<template>
  <div>
    <!-- Breadcrumb -->

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  N° Plaque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Modèle
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Energie
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date MEC
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Boite
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(a, index) in Plaques"
                :key="index"
                class="hover:bg-gray-50"
                style="cursor: pointer"
                @click="selectPlaque(a.carData[0])"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  v-if="!a.carData[0].error"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      {{ a.plaque }}
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  v-if="!a.carData[0].error"
                >
                  <div
                    v-if="a.carData[0].data.AWN_marque_carrosserie"
                    class="text-sm leading-5 text-gray-900"
                  >
                    {{ a.carData[0].data.AWN_marque_carrosserie }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  v-if="!a.carData[0].error"
                >
                  {{ a.carData[0].data.AWN_modele }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                  v-if="!a.carData[0].error"
                >
                  {{ a.carData[0].data.AWN_energie }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                  v-if="!a.carData[0].error"
                >
                  {{ a.carData[0].data.AWN_date_mise_en_circulation }}
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                  v-if="!a.carData[0].error"
                >
                  {{ a.carData[0].data.AWN_type_boite_vites }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MyArticle",
  data() {
    return {
      open: false,
      openArticle: false,
      Plaques: [],
      Familles: [],
      articleNumber: 0,
      familyName: "",
      sousfamilyName: "",
      paginateArray: [],
      paginateNextStep: 11,
      startPaginate: 1,
      selectedArticle: [],
      articleSearch: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["addvehicule", "clearvehicule", "setSelectionCarId"]),
    getPlaques() {
      axios
        .get(`${process.env.VUE_APP_API}/plaqueCRUD/${this.user.proprietaire}`)
        .then((response) => {
          this.Plaques = response.data;
        });
    },
    selectPlaque(articleTab) {
      this.clearvehicule();
      this.setSelectionCarId({ carId: null });
      this.addvehicule(articleTab);
      this.$router.push("/catalogueHubNew");
    },
  },
  mounted() {
    this.getPlaques();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
