<template>
  <div>
    <button
      @click="open = true"
      class="px-6 py-2 mt-3 font-medium tracking-wide text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none"
    >
      Créer
    </button>

    <div
    v-if="open"
      :class="`modal ${
        !open && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="open = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 52rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Ajouter un utilisateur</p>
            <div class="z-50 cursor-pointer modal-close" @click="open = false">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Nom d'utilisateur</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">account_box</span>
              </span>

              <input
                v-model="username"
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Email</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">email</span>
              </span>

              <input
                v-model="email"
                type="email"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Type de compte</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">settings_accessibility</span>
              </span>

              <select
                v-model="accountType"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              >
                <option value="Administrateur">Admin</option>
                <option value="Magasinier">Magasinier</option>
              </select>
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Equipe</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">group</span>
              </span>

              <select
                v-model="team"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              >
                <option value="Administrateur">Admin</option>
                <option value="Magasinier">Magasinier</option>
              </select>
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Mot de passe</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">password</span>
              </span>

              <input
                v-model="password"
                type="password"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>
          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label v-show="errorpassword" class="text-xs" style="color: red"
              >le mot de passe doit contenir 6 caractères minimum</label
            >
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="open = false"
              class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="inscription()"
              class="px-6 py-3 font-medium tracking-wide text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-3 sm:flex-row">
      <div class="flex">
        <div class="relative">
          <select
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:bg-white"
          >
            <option>5</option>
            <option>10</option>
            <option>20</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div class="relative">
          <select
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border-t border-b border-r border-gray-400 rounded-r appearance-none sm:rounded-r-none sm:border-r-0 focus:bg-white"
          >
            <option>All</option>
            <option>Active</option>
            <option>Inactive</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="relative block mt-2 sm:mt-0">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" class="w-4 h-4 text-gray-500 fill-current">
            <path
              d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
            />
          </svg>
        </span>

        <input
          placeholder="Search"
          class="block focus:outline-2 outline-sky-300 w-full py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none sm:rounded-l-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
        />
      </div>
    </div>
    <div class="flex flex-col mt-8">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Nom d'utilisateur
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Email
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Type de compte
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equipe
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Modifier
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in users" :key="index">
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ u.username }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.email }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.accountType }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    >{{ u.team }}</span
                  >
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <button>
                    <span class="material-icons-round">settings</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex flex-col items-center px-5 py-5 bg-white border-t xs:flex-row xs:justify-between"
          >
            <span class="text-xs text-gray-900 xs:text-sm"
              >1 à 4 sur 50 Articles</span
            >

            <div class="inline-flex mt-2 xs:mt-0">
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-l hover:bg-gray-400"
              >
                Précédent
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                1
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                2
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                3
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                4
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-r hover:bg-gray-400"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import axios from "axios";


export default {
  name:'MyUtilisateurs',
  data() {
    return {
      open:false,
      users:[],
      email:"",
      password:"",
      username:"",
      accountType:"",
      team:"",
      errorpassword:false,
    }
  },
  methods: {
    inscription(){
      if(this.password.length >= 6){
      const auth = getAuth();
        createUserWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;

            axios
              .post(`${process.env.VUE_APP_API}/user`, {
                _id: user.uid,
                email: this.email,
                username: this.username,
                accountType : this.accountType,
                team: this.team,
              })
              .then(() => {
                this.getUsers();
                this.resetUserData();
                this.open = false;
                this.errorpassword = false;
              });
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
          });
      }else{
        this.errorpassword = true;
      }
    },
    getUsers(){
      axios.get(`${process.env.VUE_APP_API}/user`).then((response)=>{
        this.users = response.data;
      })
    },
    resetUserData(){
      this.email="";
      this.password="";
      this.username="";
      this.accountType="";
      this.team="";
    }
  },
  mounted() {
    this.getUsers();
  },
}
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
