<template>
  <div
    v-if="openCreerClient"
    :class="`modal ${
      !openCreerClient && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openCreerClient = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: fit-content; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openCreerClient = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <creerClient
          v-if="openCreerClient"
          :client="clientFocus"
          @close="openCreerClient = !openCreerClient"
          @client="handleClient"
          @clientInfos="handleClientInfos"
          @isDataClient="getClients()"
        />
      </div>
    </div>
  </div>
  <div
    v-if="openDetailClient"
    :class="`modal ${
      !openDetailClient && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openDetailClient = false), getClients()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: fit-content; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openDetailClient = false), getClients()"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <detailClient
          v-if="openDetailClient"
          @client="handleClient"
          @clientInfos="handleClientInfos"
        />
      </div>
    </div>
  </div>
  <div class="flex space-x-6">
    <div class="flex relative">
      <div>
        <input
          id="enterclient"
          @click="
            (clientFocus = ''), savedatadevis({ ref: noteDoc, client: '' })
          "
          list="brow2"
          @input="
            savedatadevis({ ref: noteDoc, client: clientFocus }),
              getClientInfo()
          "
          @keyup.enter="openCreerClient = !openCreerClient"
          v-model="clientFocus"
          placeholder="Clients"
          style="width: 40em"
          class="block bg-green-200 focus:outline-2 outline-sky-300 py-2 pl-6 pr-2 text-sm text-gray-900 font-bold placeholder-gray-400 border border-gray-400 rounded-md focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 hover:bg-white"
        />
        <datalist id="brow2">
          <option
            v-for="(c, index) in dataClient"
            :key="index"
            :value="c.Name"
          ></option>
        </datalist>
      </div>
      <div class="absolute mt-2 ml-1">
        <span
          @click="
            (clientFocus = ''), savedatadevis({ ref: noteDoc, client: '' })
          "
          style="font-size: 15px"
          class="material-icons-outlined cursor-pointer rounded-full bg-white hover:bg-gray-100 text-red-500 hover:from-transparent"
          >cancel</span
        >
      </div>
    </div>
    <div class="flex space-x-4">
      <button
        title="Détail du client"
        @click="getClient()"
        class="material-icons-outlined bg-orange-500 hover:bg-orange-400 text-white p-1 rounded-full my-auto shadow"
      >
        contact_page
      </button>
      <button
        title="Ajouter un client"
        @click="openCreerClient = !openCreerClient"
        class="material-icons-outlined bg-orange-500 hover:bg-orange-400 text-white p-1 rounded-full my-auto shadow"
      >
        person_add
      </button>
      <a
        title="Liste des clients"
        class="material-icons-outlined bg-cyan-600 hover:bg-cyan-500 text-white p-1 rounded-full my-auto shadow"
        href="./listeClient"
      >
        list
      </a>
    </div>
  </div>
  <div class="mt-4">
    <div class="flex space-x-4">
      <div class="tableHEADTR relative block mt-2 sm:mt-0">
        <input
          v-on:keyup.enter="getOneArticle()"
          v-model="articleSearch"
          placeholder="Entrez un code article"
          class="block focus:outline-2 outline-sky-300 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
        />
        <div
          v-show="showArticleList"
          class="absolute z-10 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <!-- Table pour trouver un produit -->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Réf.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Dispo
                </th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix Achat
                </th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix Vente
                </th>

                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <button
                    class="cursor-pointer"
                    @click="showArticleList = false"
                  >
                    <span
                      class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25"
                      >close</span
                    >
                  </button>
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>
            <tbody class="bg-white" v-if="searchTab.length > 0">
              <tr
                @click="handlerArticle(csArticle)"
                class="bg-blue-100 hover:bg-opacity-25 cursor-pointer"
                style="max-height: 10px"
                v-for="(csArticle, index) in searchTab"
                :key="index"
              >
                <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ csArticle.article.Ref_fournisseur }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">
                    {{ csArticle.article.Code_marque }}
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    {{ csArticle.article.Description }}
                  </div>
                </td>

                <td class="px-5 border-b border-gray-200 whitespace-nowrap">
                  <div v-if="csArticle.stockData.length > 0">
                    <span
                      v-if="
                        csArticle.stockData[0].stock == 0 ||
                        csArticle.stockData[0].stock == '0'
                      "
                      >Sur commande</span
                    >
                    <span v-else>Dispo</span>
                  </div>
                  <span v-else>Sur commande</span>
                </td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-row space-x-2 items-center">
                    <span class="text-xs line-through"
                      >{{ financial(csArticle.prix.prix_brut) }} €</span
                    >
                    <span>{{ financial(csArticle.prix.prix_net) }} €</span>
                    <span
                      class="p-1 text-xs bg-orange-500 text-white rounded shadow animate-pulse"
                      v-if="csArticle.prix.promo"
                      >Promos</span
                    >
                  </div>
                </td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-row space-x-2 items-center">
                    <span class=""
                      >{{ financial(csArticle.prix.prix_brut) }} €</span
                    >
                  </div>
                </td>

                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
              </tr>
            </thead>
          </table>
          <!-- Fin Table pour trouver un produit -->
        </div>

        <div
          class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
        >
          <svg
            class="w-4 h-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
      <div class="my-auto">
        <button
          title="Saisie rapide"
          @click="(showSaisieChaud = !showSaisieChaud), getFournisseur()"
          style="font-size: 20px"
          class="relative material-icons-outlined m-auto bg-sky-500 hover:bg-sky-400 text-white p-1 rounded-md"
        >
          drive_file_rename_outline
        </button>
      </div>
      <button
        class="px-6 py-2 ml-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
        @click="creerDevis()"
      >
        Valider
      </button>
    </div>
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Dispo
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  PPC
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <div class="my-auto">Remise</div>
                    <div class="mt-0.5 m-auto cursor-pointer">
                      <div
                        v-if="affichageRemise == false"
                        @click="affichageRemise = true"
                        title="Afficher les remises d'achat"
                      >
                        <span
                          class="material-icons-outlined text-blue-500 hover:text-blue-600"
                          >expand_less</span
                        >
                      </div>
                      <div
                        v-if="affichageRemise == true"
                        @click="affichageRemise = false"
                        title="Cacher les remises d'achat"
                      >
                        <span
                          class="material-icons-outlined text-blue-500 hover:text-blue-600"
                          >expand_more</span
                        >
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white tableBODY">
              <tr
                v-for="(artDevis, index) in dataDevis"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row space-x-2 items-center">
                    <div class="space-x-1 flex text-sm leading-5 text-gray-900">
                      <span class="text-sm leading-5 text-gray-900">{{
                        artDevis.Ref_fournisseur
                      }}</span>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="flex flex-col">
                    <div class="text-sm leading-5 text-gray-900">
                      {{ artDevis.Code_marque }}
                    </div>
                    <div class="text-sm leading-5 text-gray-500">
                      {{ artDevis.Description }}
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <input
                    class="focus:outline-2 outline-sky-300 border"
                    v-model="dataDevis[index].quantity"
                    style="width: 30%"
                    type="number"
                    :min="artDevis.Qte_mini"
                    :placeholder="artDevis.Qte_mini"
                    @change="
                      setRemiseArticle(
                        index,
                        dataDevis[index].remise,
                        dataDevis[index].quantity,
                        artDevis.Prix_euro
                      )
                    "
                  />
                  <span
                    class="inline-flex text-xs font-semibold leading-5 text-red-500 rounded-full"
                    style="cursor: pointer"
                    @click="deleteArticle(artDevis)"
                  >
                    <span
                      class="material-icons-round bg-red-500 rounded-sm text-white"
                      style="font-size: small"
                    >
                      clear
                    </span>
                  </span>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <!-- revenir stock -->
                  <!-- <div class="flex space-x-1" v-if="articleAct.stockData > 0">
                    <span
                      style="width: 1.75rem; height: 1.75rem"
                      class="material-icons-outlined p-0.5 rounded-full bg-green-500 text-white"
                      >check_circle</span
                    >
                  </div>
                  <div class="flex space-x-1" v-else>
                    <span
                      style="width: 1.75rem; height: 1.75rem"
                      class="material-icons-outlined p-0.5 rounded-full bg-orange-500 text-white"
                      >help_outline</span
                    >
                  </div> -->
                  <stockvue
                    :Article="artDevis"
                    :quantity="artDevis.quantity"
                    :key="artDevis.Code_EAN + '-' + artDevis.quantity"
                  />
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(artDevis.Prix_euro).toFixed(2) }}
                  €
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="flex flex-col">
                    <div
                      class="flex space-x-4 text-sm leading-5 text-blue-500"
                      v-if="affichageRemise == true"
                    >
                      <input
                        :title="
                          artDevis.PrixFournisseurType === 'prixnet'
                            ? 'Prix achat : ' +
                              parseFloat(artDevis.Prix_vente).toFixed(2) +
                              ' €'
                            : 'Prix achat : ' +
                              (
                                parseFloat(artDevis.Prix_euro) -
                                parseFloat(artDevis.Prix_euro) *
                                  (remiseTabAchat[index] / 100)
                              ).toFixed(2) +
                              ' €'
                        "
                        v-model="remiseTabAchat[index]"
                        class="w-12 border focus:outline-sky-300 outline-2"
                        disabled
                      />%
                      <p v-if="artDevis.PrixFournisseurType === 'prixnet'">
                        prix net
                      </p>
                    </div>
                    <div class="mt-1 text-sm leading-5 text-gray-900">
                      <input
                        title="Remise du devis"
                        type="number"
                        v-model="dataDevis[index].remise"
                        min="0"
                        max="100"
                        step="5"
                        class="w-12 border focus:outline-sky-300 outline-2 text-blue"
                        @change="
                          setRemiseArticle(
                            index,
                            dataDevis[index].remise,
                            dataDevis[index].quantity,
                            artDevis.Prix_euro
                          )
                        "
                      />%
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span>
                    {{
                      (
                        parseFloat(artDevis.Prix_euro) -
                        parseFloat(artDevis.Prix_euro) *
                          (dataDevis[index].remise / 100)
                      ).toFixed(2)
                    }}
                    €</span
                  >
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{
                    (
                      (parseFloat(artDevis.Prix_euro) -
                        parseFloat(artDevis.Prix_euro) *
                          (dataDevis[index].remise / 100)) *
                      dataDevis[index].quantity
                    ).toFixed(2)
                  }}
                  €
                </td>
              </tr>

              <tr
                class="tableHEADTR shadow border bg-gray-100 rounded-lg"
                v-show="showSaisieChaud"
              >
                <td
                  class="px-6 w-44 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-col">
                    <input
                      class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                      type="text"
                      placeholder="Ref"
                      v-model="lineAddArticle_ref"
                      @keyup.enter="addManualLine()"
                    />

                    <select
                      v-model="lineAddArticle_fournisseur_selected"
                      class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                    >
                      <option value="Fournisseurs" disabled selected>
                        --Fournisseurs--
                      </option>
                      <option
                        v-for="(c, index) in fournisseurList"
                        :key="index"
                        :value="c"
                      >
                        {{ c.CompanyName }}
                      </option>
                    </select>
                  </div>
                </td>

                <td
                  class="px-6 w-80 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="flex flex-col">
                    <input
                      class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                      type="text"
                      placeholder="Marque"
                      v-model="lineAddArticle_marque"
                      @keyup.enter="addManualLine()"
                    />
                    <input
                      class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                      type="text"
                      placeholder="Description"
                      v-model="lineAddArticle_desc"
                      @keyup.enter="addManualLine()"
                    />
                    <input
                      class="mt-1 border px-1 py-0.5 focus:outline-2 outline-sky-300"
                      style="width: 50%"
                      type="text"
                      placeholder="Poids en g"
                      v-model="lineAddArticle_poids"
                      @keyup.enter="addManualLine()"
                    />
                  </div>
                </td>

                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row items-center space-x-2">
                    <input
                      disabled
                      class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                      style="width: 35%"
                      type="number"
                      v-model="lineAddArticle_quantity"
                      @keyup.enter="addManualLine()"
                    />
                    <!-- revenir ici -->
                  </div>
                </td>

                <td
                  class="border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div
                    class="m-auto text-sm bg-sky-400 hover:bg-sky-300 shadow-lg p-1 text-white rounded-md cursor-pointer"
                    @click="addManualLine()"
                  >
                    <p class="flex justify-center">Valider</p>
                    <p class="flex justify-center">saisie</p>
                  </div>
                </td>

                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <input
                    class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                    style="width: 35%"
                    type="number"
                    v-model="lineAddArticle_pv"
                    @keyup.enter="addManualLine()"
                  />
                  €
                </td>

                <td
                  class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <input
                    class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                    style="width: 35%"
                    type="number"
                    v-model="lineAddArticle_remise"
                    min="0"
                    max="100"
                    step="5"
                    @keyup.enter="addManualLine()"
                  />
                  %
                </td>

                <td
                  class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{
                    financial(
                      lineAddArticle_pv -
                        lineAddArticle_pv * (lineAddArticle_remise / 100)
                    )
                  }}
                  €
                </td>

                <td
                  class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{
                    financial(
                      (lineAddArticle_pv -
                        lineAddArticle_pv * (lineAddArticle_remise / 100)) *
                        lineAddArticle_quantity
                    )
                  }}
                  €
                </td>
              </tr>
            </tbody>
            <thead class="tableHEADTR">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div
                    class="flex w-22 cursor-pointer items-center px-2 py-2 text-gray-600 border rounded-md hover:text-white hover:bg-blue-600 hover:opacity-40 m-auto"
                    @click="clearPanier()"
                  >
                    <span class="material-icons-outlined" style="color: red"
                      >delete_forever</span
                    >
                    <button class="text-xs mx-4">
                      <span class="font-bold" href="#">Vider</span>
                    </button>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-96 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="relative flex flex-col">
                    <span>Réf. client :</span>
                    <div
                      class="my-6 absolute inset-y-0 left-0 flex items-center px-1 text-gray-700 h-4"
                    >
                      <span
                        @click="
                          (noteDoc = ''),
                            savedatadevis({ ref: '', client: clientFocus })
                        "
                        style="font-size: 15px"
                        class="material-icons-outlined cursor-pointer rounded-full bg-sky-200 hover:bg-sky-300 text-white hover:from-transparent"
                        >cancel</span
                      >
                    </div>
                    <input
                      class="block border rounded-md pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase"
                      v-model="noteDoc"
                      @input="
                        savedatadevis({ ref: noteDoc, client: clientFocus })
                      "
                    />
                  </div>
                </th>
                <th
                  class="w-96 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-4 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  {{ setQuantity }} pcs
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-4 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  @mouseover="showMargeTotal = true"
                  @mouseleave="showMargeTotal = false"
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total HT :
                    {{ financial(setTotal) }} €
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total TTC :
                    {{ financial(setTotalTTC) }} €
                  </div>
                  <div
                    v-if="dataDevis.length > 0"
                    v-show="showMargeTotal"
                    class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                  >
                    Marge total : {{ financial(setMargeTotal) }} €
                  </div>
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import stockvue from "@/components/Article/stock.vue";
import creerClient from "../../components/clientInfo/creerClient.vue";
import detailClient from "../../components/clientInfo/detailClient.vue";
import { createdevis } from "../../components/pdf/devisClient.js";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import {
  searchArticleClient,
  prepareArticleClient,
} from "@/hooks/searchEngine/article.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openCreerClient: false,
      openDetailClient: false,
      articleSearch: "",
      qtyTab: [],
      clientTab: [],
      showArticleList: false,
      affichageRemise: true,
      numDoc: 0,
      dataClient: [],
      groupClient: "",
      noteDoc: "",
      clientFocus: "",
      showMargeTotal: false,
      clientInfo: [],
      searchTab: [],

      // Saisie chaud
      showSaisieChaud: false,
      fournisseurList: [],
      lineAddArticle_quantity: 1,
      lineAddArticle_pv: 1.0,
      lineAddArticle_remise: 0,
      lineAddArticle_marque: "",
      lineAddArticle_ref: "",
      lineAddArticle_desc: "",
      lineAddArticle_poids: "",
      lineAddArticle_fournisseur_selected: null,
    };
  },
  components: {
    creerClient,
    detailClient,
    stockvue,
  },
  computed: {
    ...mapGetters([
      "dataDevis",
      "user",
      "vehicule",
      "plateform",
      "clientFocusDataDevis",
      "refDataDevis",
      "tabClient",
      "remiseTabAchat",
    ]),
    setTotal() {
      var totalRemise = 0.0;
      var totaldebut = 0.0;
      this.dataDevis.forEach((element, index) => {
        totalRemise =
          parseFloat(element.Prix_euro) -
          parseFloat(element.Prix_euro) * (this.dataDevis[index].remise / 100);
        totaldebut = totaldebut + totalRemise * this.dataDevis[index].quantity;
      });
      return totaldebut;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
    setQuantity() {
      var qty = 0;
      this.dataDevis.forEach((element) => {
        qty = qty + parseFloat(element.quantity);
      });
      return qty;
    },
    setMargeTotal() {
      var prixachattotal = 0.0;
      var prixventetotal = 0.0;
      var margetotal = 0.0;
      this.dataDevis.forEach((element, index) => {
        prixachattotal = (
          (parseFloat(element.Prix_euro) -
            parseFloat(element.Prix_euro) *
              (this.remiseTabAchat[index] / 100)) *
          this.dataDevis[index].quantity
        ).toFixed(2);
        prixventetotal = (
          (parseFloat(element.Prix_euro) -
            parseFloat(element.Prix_euro) *
              (this.dataDevis[index].remise / 100)) *
          this.dataDevis[index].quantity
        ).toFixed(2);
        margetotal =
          parseFloat(margetotal) +
          parseFloat(prixventetotal) -
          parseFloat(prixachattotal);
      });
      return margetotal;
    },
  },
  methods: {
    ...mapActions([
      "searchTranslatedDirect",
      "adddatadevis",
      "suppdatadevis",
      "cleardatadevis",
      "getclientchoosedtab",
      "updateremisedatadevis",
      "savedatadevis",
      "clearvehicule",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    setRemise() {
      for (const [index, tab] of this.dataDevis.entries()) {
        if (!tab.remise) {
          tab.remise = 0;
        }
      }
    },
    getClients() {
      axios
        .post(`${process.env.VUE_APP_API}/clientSpaceClient/getbyowner`, {
          plateform: this.user.plateform,
          Client: this.user.proprietaire,
        })
        .then((response) => {
          this.dataClient = response.data;
        });
    },
    async getClientInfo() {
      if (this.clientFocus) {
        axios
          .post(
            `${process.env.VUE_APP_API}/clientSpaceClient/getone/${this.clientFocus}`,
            {
              plateform: this.user.plateform,
              Client: this.user.proprietaire,
            }
          )
          .then((response) => {
            this.clientInfo = response.data;
          });
      }
    },
    async getClient() {
      if (this.clientFocus) {
        await this.getClientInfo();
        this.getclientchoosedtab(this.clientInfo[0]);
        this.openDetailClient = !this.openDetailClient;
      } else {
        this.toast.warning("Choisissez un client !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.plateform,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    async getOneArticle() {
      if (this.articleSearch != "") {
        let plateform_mere = this.user.plateform;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        this.searchTab = await searchArticleClient(
          this.articleSearch,
          plateform_mere,
          this.user.proprietaire,
          this.tabClient.Group.Name
        );

        this.showArticleList = true;
      }
    },
    // autoComplete(ref) {
    //   if (this.articleSearch != "") {
    //     axios
    //       .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
    //       .then((response) => {
    //         this.showAutoComplete = true;
    //         this.autoCompleteTab = response.data;
    //       });
    //   } else {
    //     this.showAutoComplete = false;
    //   }
    // },
    async getPrixNetClient(data) {
      this.tabPrixNetClient = [];
      this.tabPrixNetFournClient = "";
      const tabClient = await axios.post(
        `${process.env.VUE_APP_API}/client/getprixnet`,
        {
          plateform: this.user.plateform,
          Client: [this.tabClient],
          Ref_fournisseur: data.Ref_fournisseur,
          Prefixe_tarif: data.Prefixe_tarif,
        }
      );
      if (tabClient.data.tabPrixNet.length > 0) {
        this.tabPrixNetClient.push(tabClient.data.tabPrixNet[0]);
      }
      if (tabClient.data.client != "") {
        this.tabPrixNetFournClient = tabClient.data.client;
      }
    },
    async getPrixNetFournisseur(data) {
      this.tabPrixNetFourn = [];
      this.tabPrixNetFournClient = "";
      const tabFournisseur = await axios.post(
        `${process.env.VUE_APP_API}/fournisseur/getprixnet`,
        {
          plateform: this.user.plateform,
          Prefixe_tarif: data.Prefixe_tarif,
          Ref_fournisseur: data.Ref_fournisseur,
          Client: [this.tabClient],
        }
      );
      if (tabFournisseur.data.tabPrixNet.length > 0) {
        this.tabPrixNetFourn.push(tabFournisseur.data.tabPrixNet[0]);
      }
      if (tabFournisseur.data.client != "") {
        this.tabPrixNetFournClient = tabFournisseur.data.client;
      }
    },
    async handlerArticle(structure) {
      var existArticle = false;

      for (const [index, actArticle] of this.dataDevis.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif &&
          actArticle.Code_marque == structure.article.Code_marque
        ) {
          this.dataDevis[index].quantity =
            parseInt(this.dataDevis[index].quantity) + 1;
          existArticle = true;
          this.setRemiseArticle(
            index,
            this.dataDevis[index].remise,
            this.dataDevis[index].quantity,
            actArticle.Prix_euro
          );
        }
      }

      if (existArticle == false) {
        let plateform_mere = this.user.plateform;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        var dataTab = await prepareArticleClient(
          structure,
          plateform_mere,
          this.tabClient.Name,
          this.tabClient.Group.Name,
          1
        );

        await this.addConsigne(dataTab);
        await this.adddatadevis({ data: dataTab, data2: false });

        // const foundArticleClient = await axios.post(
        //   `${process.env.VUE_APP_API}/articleSpaceClient/getbyowner`,
        //   {
        //     plateform: this.user.plateform,
        //     Owner: this.user.proprietaire,
        //     ref: structure.Ref_fournisseur,
        //   }
        // );

        // if (foundArticleClient.data.length > 0) {
        //   await this.adddatadevis(foundArticleClient.data);
        // }
      }

      // this.showAutoComplete = false;
      this.showArticleList = false;
      this.articleSearch = "";
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        this.adddatadevis({
          data: {
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          },
          data2: false,
        });
      }
    },
    setRemiseArticle(ind, rem, qty, prix) {
      const PrixFinal = parseFloat(prix) - parseFloat(prix) * (rem / 100);
      const prixTotal = PrixFinal * qty;
      const dataArt = {
        indexArt: ind,
        quantity: qty,
        remise: rem,
        prixtotal: prixTotal,
        prixfinal: PrixFinal,
      };
      this.updateremisedatadevis(dataArt);
    },
    prepareQuantity() {
      this.dataDevis.forEach((element, index) => {
        this.dataDevis[index].remise = 0;
        this.dataDevis[index].quantity = element.quantity;
        if (element.PrixFournisseurType == "prixnet") {
          this.remiseTabAchat[index] = 0;
        }
      });
    },
    getGroupClient() {
      axios
        .post(
          `${process.env.VUE_APP_API}/client/getone/${this.user.proprietaire}`,
          {
            plateform: this.user.plateform,
          }
        )
        .then((response) => {
          this.translateGroupClient(response.data[0].Group.Name);
          this.clientTab = response.data;
          this.groupClient = response.data[0].Group.Name;
        });
    },
    translateGroupClient(gpClient) {
      this.dataDevis.forEach((element, index) => {
        if (element.prix_quantitatif && element.prix_quantitatif == true) {
          //que faire ?
        } else {
          axios
            .post(
              `${process.env.VUE_APP_API}/conditionFournisseur/getClientCondition`,
              {
                plateform: this.user.plateform,
                fournisseur: element.FournisseurSelected,
                article: element,
              }
            )
            .then((response) => {
              if (
                (element.PrixFournisseurType == "remise" ||
                  element.PrixFournisseurType == "coef1" ||
                  element.PrixFournisseurType == "coef2") &&
                response.data.length > 0
              ) {
                if (
                  element.PrixFournisseurType == "coef1" ||
                  element.PrixFournisseurType == "coef2"
                ) {
                  if (element.PrixFournisseurType == "coef2") {
                    this.dataDevis[index].Prix_euro =
                      this.dataDevis[index].Prix_ppc;
                  }
                  this.dataDevis[index].Prix_euro =
                    this.dataDevis[index].Prix_euro *
                    response.data[0].remiseFournisseur;
                }
                switch (gpClient) {
                  case "T1":
                    this.remiseTabAchat[index] = response.data[0].T1;
                    break;
                  case "T2":
                    this.remiseTabAchat[index] = response.data[0].T2;
                    break;
                  case "T3":
                    this.remiseTabAchat[index] = response.data[0].T3;
                    break;
                  case "T4":
                    this.remiseTabAchat[index] = response.data[0].P1;
                    break;
                  case "T5":
                    this.remiseTabAchat[index] = response.data[0].P2;
                    break;
                  case "T6":
                    this.remiseTabAchat[index] = response.data[0].P3;
                    break;
                  default:
                    this.remiseTabAchat[index] = 0;
                    break;
                }
              }
            });
        }
      });
    },
    async addManualLine() {
      var dataTab = {};
      var m = Math;
      var d = Date;
      var h = 16;
      var s = (s) => m.floor(s).toString(h);
      dataTab._id =
        s(d.now() / 1000) +
        " ".repeat(h).replace(/./g, () => s(m.random() * h));
      dataTab.PrixFournisseurType = "remise";

      dataTab.quantity = this.lineAddArticle_quantity;
      dataTab.remise = this.lineAddArticle_remise;
      dataTab.commentaire = "";
      dataTab.prixtotal = dataTab.Prix_euro;
      dataTab.prixfinal = dataTab.Prix_euro;
      dataTab.Ref_fournisseur = this.lineAddArticle_ref;
      dataTab.Code_marque = this.lineAddArticle_marque;
      dataTab.Description = this.lineAddArticle_desc;
      dataTab.Poids = this.lineAddArticle_poids;
      dataTab.Prix_euro = this.lineAddArticle_pv;
      if (this.lineAddArticle_fournisseur_selected) {
        dataTab.fournisseur =
          this.lineAddArticle_fournisseur_selected.CompanyName;
        dataTab.Prefixe_tarif =
          this.lineAddArticle_fournisseur_selected.Prefixe_tarif;
        dataTab.FournisseurSelected =
          this.lineAddArticle_fournisseur_selected.CompanyName;
      } else {
        dataTab.FournisseurSelected = "Aucun";
      }
      await axios.post(`${process.env.VUE_APP_API}/articleSpaceClient`, {
        plateform: this.user.plateform,
        Owner: this.user.proprietaire,
        Ref_fournisseur: dataTab.Ref_fournisseur,
        Ref_fournisseur_trim: dataTab.Ref_fournisseur,
        Ref_complementaire: dataTab.Ref_fournisseur,
        Prix_euro: dataTab.Prix_euro,
        Code_marque: dataTab.Code_marque,
        fournisseur: dataTab.fournisseur,
        FournisseurSelected: dataTab.FournisseurSelected,
        Prefixe_tarif: dataTab.Prefixe_tarif,
        commentaire: dataTab.commentaire,
        Description: dataTab.Description,
        Poids: dataTab.Poids,
        remise: parseInt(dataTab.remise),
        Qte_mini: parseInt(dataTab.quantity),
      });
      this.adddatadevis({ data: dataTab, data2: false });
      this.showSaisieChaud = false;
    },
    async creerDevis() {
      await this.setRemise();
      if (this.clientFocus != "") {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        var hour = today.getHours();
        var seconds = today.getSeconds();
        var minutes = today.getMinutes();
        today = `${dd}/${mm}/${yyyy} ${hour}:${minutes}:${seconds}`;
        var pdfinfo = [];
        this.dataDevis.forEach((element, index) => {
          // prixRemise =
          //   element.Prix_euro - element.Prix_euro * (this.dataDevis[index].remise / 100);
          // prixRemiseSupp =
          //   prixRemise - prixRemise * (this.remiseTabSCD[index] / 100);
          pdfinfo[index] = {
            description: element.Description,
            ref: element.Ref_fournisseur,
            quantite: parseInt(this.dataDevis[index].quantity),
            remise: parseInt(this.dataDevis[index].remise),
            commentaire: "",
            remiseSupp: 0,
            prixVente: element.Prix_euro,
            prixU: element.Prix_euro * this.dataDevis[index].quantity,
            // total: prixRemiseSupp * this.dataDevis[index].quantity,
            total:
              element.Prix_euro -
              element.Prix_euro * (this.dataDevis[index].remise / 100),
          };
        });
        await axios
          .post(`${process.env.VUE_APP_API}/devisSpaceClient/getlast`, {
            plateform: this.user.plateform,
            Client: this.user.proprietaire,
          })
          .then((response) => {
            this.numDoc = response.data + 1;
          });

        await axios.post(`${process.env.VUE_APP_API}/devisSpaceClient`, {
          plateform: this.user.plateform,
          Owner: this.user.proprietaire,
          Client: this.clientFocus,
          Name: this.user.username,
          Numero: this.numDoc,
          Date: new Date(),
          clientInfo:
            this.clientInfo.length > 0
              ? this.clientInfo
              : [{ Name: this.user.proprietaire }],
          Articles: this.dataDevis,
          pdfData: pdfinfo,
          Note: this.noteDoc,
          TotalPrice: this.setTotal,
          TotalPriceTTC: this.setTotalTTC,
          reglement: [],
          archived: false,
        });
        await this.saveDocument(pdfinfo);
      } else {
        this.toast.warning("Choisissez un client !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async saveDocument(pdfinfo) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      // await axios.post(`${process.env.VUE_APP_API}/devisClient`, {
      //   Type: "Devis",
      //   plateform: this.user.plateform,
      //   Owner: this.user.proprietaire,
      //   Client: this.clientFocus,
      //   Name: this.user.username,
      //   devisNumber: this.numDoc,
      //   Date: today,
      //   clientInfo: this.clientInfo[0],
      //   Articles: this.dataDevis,
      //   Note: this.noteDoc,
      //   TotalPrice: this.setTotal,
      //   TotalPriceTTC: this.setTotalTTC,
      //   archived: false,
      // });
      await this.toast.success("Devis créé !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.$router.push("./listeDevisClient");
      this.createPDF({
        plateform: this.user.plateform,
        Owner: this.user.proprietaire,
        Client: this.clientFocus,
        Name: this.user.username,
        Numero: this.numDoc,
        Date: new Date(),
        clientInfo: this.clientInfo,
        Articles: this.dataDevis,
        pdfData: pdfinfo,
        Note: this.noteDoc,
        TotalPrice: this.setTotal,
        TotalPriceTTC: this.setTotal + this.setTotal * 0.2,
        reglement: [],
        archived: false,
      });
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;

      if (dateedition == datepdf) {
        createdevis(objet, false);
      } else {
        createdevis(objet, true);
      }
      this.clearPanier();
      this.noteDoc = "";
      this.articleSearch = "";
      this.clientFocus = "";
      this.savedatadevis({ ref: "", client: "" });
      this.clearvehicule();
    },
    deleteArticle(dataArt, index) {
      this.suppdatadevis({ id: dataArt._id, index: index });
      // this.getGroupClient();
      // this.prepareQuantity();
    },
    clearPanier() {
      this.cleardatadevis();
    },
    // setAutoComplete(ref) {
    //   this.articleSearch = ref;
    //   this.showAutoComplete = false;
    //   this.getOneArticle();
    //   this.showAjoutArticle = false;
    //   this.showArticleList = true;
    // },
    handleClient(data) {
      this.clientFocus = data;
    },
    handleClientInfos(data) {
      this.clientInfo[0] = data;
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    // this.getFournisseurDispo();
    console.log(this.dataDevis);
    this.clientFocus = this.clientFocusDataDevis;
    this.getClients();
    this.getClientInfo();
    // this.getGroupClient();
    // this.prepareQuantity();
    if (this.refDataDevis != "") {
      this.noteDoc = this.refDataDevis;
    } else if (this.vehicule.length > 0) {
      this.noteDoc =
        this.vehicule[0].data.AWN_immat +
        " " +
        this.vehicule[0].data.AWN_modele;
    }
  },
};
</script>
<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

.tableTDTH {
  text-align: left;
  padding: 8px;
}

.tableBODY {
  display: block;
  height: 60vh;
  overflow: auto;
}

.tableHEADTR {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
