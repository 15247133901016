<template>
  <div>
    <div class="flex flex-col space-y-1">
      <div v-if="Prix.prix_brut" class="font-bold text-sm">
        <span class="text-xs">Prix Brut : </span>
        {{ parseFloat(Prix.prix_brut).toFixed(2) }} €
      </div>
      <div v-if="Prix.prix_net || Prix.prix_net >= 0" class="font-bold text-sm">
        <span class="text-xs">Prix Net : </span
        >{{ parseFloat(Prix.prix_net).toFixed(2) }} €
      </div>
      <div
        v-if="tmp_article.Montant_consigne"
        class="flex flex-row space-x-1 text-sm items-center"
      >
        <span class="material-icons-round text-green-500"> recycling </span>
        <span>{{ parseFloat(tmp_article.Montant_consigne).toFixed(2) }} €</span>
      </div>

      <div
        v-if="tmp_article.fraisdeport"
        class="flex flex-row space-x-1 text-sm items-center font-bold text-blue-500 text-xs"
      >
        <span>Frais de port:</span>
        <span>{{ tmp_article.fraisdeport }}</span>
        <span>€</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "componentPrix",
  data() {
    return {
      Prix: {
        tmp_article: {},
        promo: false,
        prix_achat: 0.0,
        prix_brut: 0.0,
        prix_net: 0.0,
      },
    };
  },
  props: {
    article: { type: Object, default: () => ({}) },
    PrixSystem: {
      type: Object,
      default: () => ({
        promo: false,
        prix_achat: 0.0,
        prix_brut: 0.0,
        prix_net: 0.0,
      }),
    },
  },
  computed: {
    ...mapGetters(["user", "tabClient", "plateform"]),
  },
  beforeMount() {
    this.tmp_article = this.article;
  },
  async mounted() {
    let plateform_mere = this.user.plateform;
    if (this.plateform.plateform_mere) {
      plateform_mere = this.plateform.plateform_mere;
    }
    let getPrix = await axios.post(
      `${process.env.VUE_APP_API}/searchEngine/getPrix`,
      {
        article: this.article,
        plateform: plateform_mere,
        Client: this.user.proprietaire,
        groupeClient: this.tabClient.Group.Name,
        quantity: 1,
      }
    );

    this.Prix = getPrix.data.data[getPrix.data.indexSelected].prixData;

    this.tmp_article = getPrix.data.data[getPrix.data.indexSelected].article;
    this.$emit("priorityChecked", {
      art: getPrix.data.data[getPrix.data.indexSelected].article,
      prix: getPrix.data.data[getPrix.data.indexSelected].prixData,
    });
  },
};
</script>
<style></style>
