import axios from "axios";

export async function MisePanier(id: string, ref: string) {
  const savedSearch = await axios.post(
    `${process.env.VUE_APP_API}/savedSearch/MisePanier`,
    {
      id: id,
      ref: ref,
    }
  );

  return savedSearch.data;
}
