<template>
  <div class="flex justify-center">
    <div class="flex flex-col space-y-1">
      <h1 class="mx-auto font-bold underline">Total des achats HT du mois courant</h1>
      <div class="relative flex justify-center items-center h-[19rem]">
        <!-- Graphique -->
        <Chart
          type="doughnut"
          :data="chartData"
          :options="chartOptions"
          class="h-[19rem] z-10"
        />
        <!-- Texte au centre -->
        <div class="absolute flex text-center mt-12 font-medium text-bluevk">
          <div class="flex flex-col">
            <div class="flex space-x-1 font-medium">
              <p class="text-bluevk">
                {{ parseFloat(salesClientMonthly).toFixed(2) }} €
              </p>
              <p v-if="tabClient?.francodeportMois" class="text-green-600">
                / {{ tabClient?.francodeportMois }} €
              </p>
            </div>
          </div>
        </div>
      </div>
      <p v-if="franco" class="mx-auto text-green-600">Franco Mensuel atteint !</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Chart from "primevue/chart";

export default {
  name: "ClientChart",
  components: { Chart },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#42A5D5", "#FF914D"],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        cutout: "69%",
        plugins: {
          legend: {
            display: true, // Afficher les étiquettes (true)
            position: "top", // Espacement entre les étiquettes
            labels: {
              padding: 30, // Largeur des cases de couleur
              boxWidth: 20, // Largeur des cases de couleur
              boxHeight: 15, // Hauteur des cases de couleur (Chart.js 3.7+)
              font: {
                size: 14, // Taille de la police
                family: "Arial", // Type de police
              },
            },
            tooltip: {
              backgroundColor: "rgba(51, 51, 51, 1)",
              callbacks: {
                label(context) {
                  const value = context.raw; // Valeur des données
                  return `${value} €`; // Ajout de "€" à la fin
                },
              },
            },
          },
        },
      },
      startDate: null,
      endDate: null,
      salesClientMonthly: null,
      franco: false,
    };
  },
  props: ["clientID"],
  computed: {
    ...mapGetters(["user", "tabClient"]),
  },
  methods: {
    getSalesOfMonth() {
      axios
        .post(`${process.env.VUE_APP_API}/venteStat/getSalesOfMonth`, {
          plateform: this.user.plateform,
          client: this.tabClient?._id,
        })
        .then((response) => {
          if (this.tabClient?.francodeportMois) {
            this.chartData.labels = ["Achats", "Différence"];
          } else {
            this.chartData.labels = ["Achats"];
          }

          let difference = 0;
          if (
            this.tabClient?.francodeportMois &&
            parseFloat(this.tabClient?.francodeportMois) -
              parseFloat(response.data?.totalHT).toFixed(2) >=
              0
          ) {
            difference =
              parseFloat(this.tabClient?.francodeportMois) -
              parseFloat(response.data?.totalHT).toFixed(2);
          }

          this.chartData.datasets[0].data = [
            parseFloat(response.data?.totalHT).toFixed(2),
            difference,
          ];

          this.salesClientMonthly = response.data?.totalHT;

          if (
            this.tabClient?.francodeportMois &&
            parseFloat(response.data?.totalHT).toFixed(2) >=
              parseFloat(this.tabClient?.francodeportMois)
          ) {
            this.franco = true;
          }
        });
    },
  },
  beforeMount() {
    this.getSalesOfMonth();
    // this.chartData.datasets[0].data = [
    //   parseFloat(this.tabClient.francodeportMois),
    //   parseFloat(this.tabClient.francodeportMois) - 300,
    // ];
  },
};
</script>
