<template>
  <div>
    <!-- Modal Detail Articles -->
    <div
      v-if="showDetailArticles"
      :class="`modal ${
        !showDetailArticles && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showDetailArticles = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 70rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Récapitulatif de la commande</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showDetailArticles = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/3 ml-2">
              Commentaire:
              {{ cmdTmp.Commentaire }}
            </div>
            <div class="flex">
              <div class="flex flex-col w-14 h-8 justify-end mt-2 mx-8">
                <span
                  v-if="BCNumbers"
                  class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                  style="color: blue"
                  @click="showBC(cmdTmp)"
                >
                  download
                </span>
                <!--  -->
                <span
                  v-if="BCNumbers"
                  class="cursor-pointer text-xs m-auto text-gray-700"
                  @click="showBC(cmdTmp)"
                >
                  BC n°{{ BCNumbers }}
                </span>
              </div>
              <!--  -->
              <div class="flex flex-col w-14 h-8 justify-end mt-2 mx-10">
                <span
                  v-if="BLNumbers"
                  class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                  style="color: blue"
                  @click="showBL(cmdTmp)"
                >
                  download
                </span>
                <!--  -->
                <span
                  v-if="BLNumbers"
                  class="cursor-pointer text-xs m-auto text-gray-700"
                  @click="showBL(cmdTmp)"
                >
                  BL n°{{ BLNumbers }}
                </span>
              </div>
            </div>
          </div>
          <!--Body-->
          <table class="min-w-full mt-4">
            <div class="overflow-y-auto max-h-[25rem]">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white w-fit">
                <tr
                  v-for="(r, index) in Articles"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ r.Ref_fournisseur }} - {{ r.Commentaire }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-900">
                      {{ r.Code_marque }}
                    </div>
                    <div class="text-sm leading-5 text-gray-500">
                      {{ r.Description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ r.quantity }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(r.Prix_euro).toFixed(2) }} €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div v-if="r.PrixFournisseurType != 'prixnet'">
                      {{ r.remise }} %
                    </div>
                    <div v-else>prix net</div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div v-if="r.PrixFournisseurType != 'prixnet'">
                      {{
                        (
                          parseFloat(r.Prix_euro) -
                          parseFloat(r.Prix_euro) * (r.remise / 100)
                        ).toFixed(2)
                      }}
                      €
                    </div>
                    <div v-else>
                      {{ parseFloat(r.Prix_vente).toFixed(2) }} €
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div v-if="r.PrixFournisseurType != 'prixnet'">
                      {{ (r.Prix_vente * r.quantity).toFixed(2) }}
                      €
                    </div>
                    <div v-else>
                      {{
                        (
                          parseFloat(r.Prix_vente) * parseFloat(r.quantity)
                        ).toFixed(2)
                      }}
                      €
                    </div>
                  </td>
                </tr>
              </tbody>
            </div>
            <thead class="tableHEADTR mt-12 border-t">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total HT : {{ parseFloat(tmpTotalPrice).toFixed(2) }} €
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total TTC : {{ parseFloat(tmpTotalPriceTTC).toFixed(2) }} €
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
              </tr>
            </thead>
          </table>

          <!--Footer-->
          <!-- <div class="flex justify-end pt-2">
            <button
              @click="showDetailArticles = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Fin modal Detail Articles -->

    <div class="flex flex-col">
      <searchInput
        placeholder="Rechercher dans commandes"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />

      <div class="flex justify-between">
        <div class="flex mt-4 space-x-4">
          <button
            v-if="archiveState == false"
            @click="getInfosCommandes(1, 10, false)"
            class="bg-bluevk text-white p-2 rounded-md w-24 border border-bluevk"
          >
            En cours
          </button>
          <button
            v-else
            @click="getInfosCommandes(1, 10, false)"
            class="bg-bluevk text-white p-2 rounded-md w-24 bg-opacity-50 border border-white hover:bg-opacity-80"
          >
            En cours
          </button>
          <button
            v-if="archiveState == true"
            @click="getInfosCommandes(1, 10, true)"
            class="bg-bluevk text-white p-2 rounded-md w-24 border border-bluevk"
          >
            Autre
          </button>
          <button
            v-else
            @click="getInfosCommandes(1, 10, true)"
            class="bg-bluevk text-white p-2 rounded-md w-24 bg-opacity-50 border border-white hover:bg-opacity-80"
          >
            Autre
          </button>
        </div>
        <div v-if="plateform.structure_depot == true" class="my-auto">
          <div v-if="cdtnDsv == false">
            <input
              type="checkbox"
              class="border"
              v-model="dsvcheck"
              @change="filterCmdDsv()"
            />
            <label>DA SILVA</label>
          </div>
          <div v-else>
            <input
              type="checkbox"
              checked
              class="border"
              v-model="dsvcheck"
              @change="getInfosCommandes(currentPage, 10, archiveState)"
            />
            <label>DA SILVA</label>
          </div>
        </div>
      </div>

      <div class="overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  N° Commande
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Réf.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Statut
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Commandé par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Progression
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  HT
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  TTC
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  BL
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in infosCommandes"
                :key="index"
                class="hover:shadow-lg cursor-pointer"
                @click="(showDetailArticles = true), getDetailArticles(u)"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <div class="ml-4 flex flex-col">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ u.BCNumber }}
                      </div>
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ u.Fournisseur }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1em"
                >
                  {{ u.Note }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ getFRDate(u.Date) }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 rounded-full"
                  >
                    {{ u.status }}
                  </span>
                  <div
                    v-if="u.status == 'Commande annulée' && u.cancellation"
                    class="text-xs"
                    :title="u.cancellation.reason"
                  >
                    <h1>
                      Raison d'annulation :
                      {{
                        u.cancellation.reason.length > 11
                          ? u.cancellation.reason.substr(0, 11) + "..."
                          : u.cancellation.reason
                      }}
                    </h1>
                    <h1>Par : {{ u.cancellation.user }}</h1>
                  </div>
                  <div
                    v-else-if="
                      u.status == 'Commandé chez le fournisseur' &&
                      u.commandeFournisseur.deliveryDateToCustomer != ''
                    "
                    class="text-xs"
                    :title="u.commandeFournisseur.deliveryDateToCustomer"
                  >
                    <h1>
                      Date livraison estimée :
                      {{ u.commandeFournisseur.deliveryDateToCustomer }}
                    </h1>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    >{{ u.Name }}</span
                  >
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <progressBar
                    v-if="u.status == 'Commande annulée'"
                    :pourcentageStatus="parseInt(u.PourcentageStatus)"
                    :isRed="true"
                  />
                  <progressBar
                    v-else
                    :pourcentageStatus="parseInt(u.PourcentageStatus)"
                    :isRed="false"
                  />
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice).toFixed(2) }} €
                </td>

                <td
                  v-if="u.TotalPriceTTC"
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
                </td>
                <td
                  v-else
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
                  €
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex w-fit">
                    <span
                      class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                      v-if="u.BLNumber"
                      style="color: blue"
                      @click="showBL(u)"
                    >
                      download
                    </span>
                    <span class="m-auto">{{ u.BLNumber }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosBdcLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosBdcLength / 10)"
            :clickFunction="
              (arg1, arg2) => getInfosCommandes(arg1, arg2, archiveState)
            "
            :currentPage="currentPage"
            :typeList="'commandes'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'commandes'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { createlivraison } from "../../components/pdf/livraison.js";
import { createcommande } from "../../components/pdf/commande.js";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import ProgressBar from "@/components/Commandes/progressBar.vue";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  name: "MyCommandesPro",
  data: () => ({
    showDetailArticles: false,
    infosCommandes: [],
    Articles: [],
    remiseTab: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0,
    ],
    qtyTab: [],
    remiseTabSCD: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0,
    ],
    tmpTotalPrice: "",
    tmpTotalPriceTTC: "",
    BLNumbers: "",
    BCNumbers: "",
    cmdTmp: [],
    tabDasilva: [],
    dsvcheck: false,
    cdtnDsv: false,

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    currentPage: 1,
    infosBdcLength: 0,

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    archiveState: false,
  }),
  components: {
    ProgressBar,
    pageNumber,
    searchInput,
  },
  computed: {
    ...mapGetters(["actionArticle", "user", "plateform"]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      var totalRemiseSupp = 0.0;
      this.actionArticle.forEach((element, index) => {
        totalRemise =
          parseFloat(element.Prix_euro) -
          parseFloat(element.Prix_euro) * (this.remiseTab[index] / 100);
        totalRemiseSupp =
          totalRemise - totalRemise * (this.remiseTabSCD[index] / 100);
        total = total + totalRemiseSupp * this.qtyTab[index];
      });
      return total;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
    setQuantity() {
      var qty = 0;
      this.actionArticle.forEach((element) => {
        qty = qty + parseFloat(element.quantity);
      });
      return qty;
    },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosCommandes(skipValue, limitValue, archive) {
      this.currentPage = skipValue;
      this.archiveState = archive;
      axios
        .post(
          `${process.env.VUE_APP_API}/commandesClient/getbyclient/getpage`,
          {
            plateform: this.user.plateform,
            client: this.user.proprietaire,
            archive: archive,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((response) => {
          this.infosCommandes = response.data;
          this.getInfosBdcLength();
          this.tabDasilva = [];
          this.cdtnDsv = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosBdcLength() {
      axios
        .post(`${process.env.VUE_APP_API}/commandesClient/getbyclient/count`, {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
          archive: this.archiveState,
        })
        .then((response) => {
          this.infosBdcLength = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterCmdDsv() {
      this.infosCommandes.forEach((element) => {
        if (element.Fournisseur && element.Fournisseur == "DA SILVA") {
          this.tabDasilva.push(element);
        }
      });
      this.infosCommandes = this.tabDasilva;
      this.cdtnDsv = true;
    },
    getDetailArticles(u) {
      this.cmdTmp = u;
      this.Articles = u.Articles;
      this.tmpTotalPrice = u.TotalPrice;
      this.tmpTotalPriceTTC = u.TotalPriceTTC;
      this.BLNumbers = u.BLNumber;
      this.BCNumbers = u.BCNumber;
    },
    showBL(cmd) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: { BLNumber: cmd.BLNumber, plateform: this.user.plateform },
        })
        .then((response) => {
          createlivraison(response.data[0], false, false);
        });
    },
    showBC(cmd) {
      axios
        .post(`${process.env.VUE_APP_API}/commandes/getone`, {
          uid: { BCNumber: cmd.BCNumber, plateform: this.user.plateform },
        })
        .then((response) => {
          createcommande(response.data[0], false);
        });
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosCommandes(skipValue, limitValue, this.archiveState);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/commandesClient/searchInClient`, {
            plateform: this.user.plateform,
            client: this.user.proprietaire,
            searchTerm: searchTerm,
            archive: this.archiveState,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosCommandes = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
  },
  mounted() {
    this.getInfosCommandes(1, 10, false);
    this.pageSwitch();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
