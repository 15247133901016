<template>
  <div
    class="bg-white rounded-sm shadow p-2 w-11/12"
    v-if="famillesGeneral.length > 0"
  >
    <div
      class="text-lg text-bluevk font-bold"
      v-if="famillesGeneral.length > 0"
    >
      Familles Connexe
    </div>
    <div class="flex flex-col space-y-1 ml-2 mt-2">
      <div v-for="(famG, indexFamG) in famillesGeneral" :key="indexFamG">
        <div
          class="px-1 hover:text-bluevk cursor-pointer"
          @click="getArticles(famG)"
        >
          <span class="">{{ famG.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="bg-white rounded-sm shadow p-2 w-11/12 mt-4"
    v-if="famillesUniversel.length > 0"
  >
    <div
      class="text-lg text-bluevk font-bold"
      v-if="famillesUniversel.length > 0"
    >
      Universel
    </div>
    <div class="flex flex-col space-y-1 ml-2 mt-2">
      <div v-for="(famU, indexFamU) in famillesUniversel" :key="indexFamU">
        <div
          class="px-1 hover:text-bluevk cursor-pointer"
          @click="getArticles(famU)"
        >
          <span class="">{{ famU.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="bg-white rounded-sm shadow p-2 w-11/12 mt-4"
    v-if="famillesElectric.length > 0"
  >
    <div
      class="text-lg text-bluevk font-bold"
      v-if="famillesElectric.length > 0"
    >
      Electrique
    </div>
    <div class="flex flex-col space-y-1 ml-2 mt-2">
      <div v-for="(famE, indexFamE) in famillesElectric" :key="indexFamE">
        <div
          class="px-1 hover:text-bluevk cursor-pointer"
          @click="getArticles(famE)"
        >
          <span class="">{{ famE.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "connexeSousFamille",
  data() {
    return {
      famillesGeneral: [],
      famillesUniversel: [],
      famillesElectric: [],
    };
  },
  computed: {
    ...mapGetters(["user", "familleSelected", "selectionCarId"]),
  },
  methods: {
    ...mapActions(["setfamilleselected"]),
    async getArticles(famille) {
      this.setfamilleselected({ famille: famille });
      this.$router.push("/catalogueTecDocSousFamille");
    },
  },
  async mounted() {
    const getGeneralFamille = await axios.post(
      `${process.env.VUE_APP_API}/configurationCatalogueV2/getGeneralSousFamille`,
      {
        plateform: this.user.plateform,
        id: this.familleSelected._id,
        carId: this.selectionCarId,
      }
    );
    this.famillesGeneral = getGeneralFamille.data.general;
    this.famillesUniversel = getGeneralFamille.data.universel;
    this.famillesElectric = getGeneralFamille.data.electric;
  },
};
</script>
<style></style>
