<template>
  <div>
    <!-- Breadcrumb -->
    <div
      v-if="openSave"
      :class="`modal ${
        !openSave && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openSave = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 48rem; max-height: 48rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Enregistrer un client</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openSave = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <!--Créer/Modifier un client-->
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <button
              class="px-16 py-2.5 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none text-xl"
              type="button"
              value=""
              @click="isHidden = !isHidden"
            >
              Créer/Modifier
            </button>
          </div>

          <div>
            <!--Modifier un client existant-->
            <div v-show="isHidden" id="Modifier" class="w-full">
              <div
                class="mt-10 mb-2.5 font-medium text-xl border-solid border-b-2 border-blue-500 bg-blue-100"
              >
                <label>Modifier un client existant</label>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs"> Choisir un client existant </label>

                <div
                  class="flex flex-col sm:flex-row relative mt-2 rounded-md shadow-sm"
                >
                  <div class="flex">
                    <div class="relative">
                      <input
                        v-on:keyup.enter="client = !client"
                        list="brow2"
                        v-model="clientChosed"
                        placeholder="Sélectionner un client existant"
                        style="width: 33rem"
                        class="block focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none"
                      />

                      <datalist id="brow2" class="">
                        <option
                          v-for="(cl, index) in Clients"
                          :key="index"
                          :value="cl.Name"
                        ></option>
                      </datalist>

                      <div
                        class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                      >
                        <svg
                          class="w-4 h-4 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container w-full" v-show="client">
                <div class="tab-pane active" id="tab-default">
                  <ul
                    class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-2"
                    id="tabs-tab"
                  >
                    <li>
                      <a
                        href="#tab-default"
                        aria-current="page"
                        class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                        >Général</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-config"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Config</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-coordinates"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Coordonnées</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-transport"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Transport</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-financial"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Finance</a
                      >
                    </li>
                  </ul>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Nom</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="nomduclient"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Professionnel ?</label>

                    <div class="flex relative mt-2 rounded-md shadow-sm">
                      <div
                        class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="radio"
                          @click="professionnel = true"
                          value="true"
                          data-val="true"
                          data-val-required="Ce champ est obligatoire."
                          id="CanLogin"
                          name="CanLogin"
                          data-enable="True"
                          data-for-field="CanLogin"
                        />
                        <label class="pl-2 checkbox">Oui</label>
                      </div>

                      <div
                        class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="radio"
                          @click="professionnel = false"
                          value="false"
                          id="CanLogin"
                          name="CanLogin"
                          data-enable="True"
                          data-for-field="CanLogin"
                        />
                        <label class="pl-2 checkbox">Non</label>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Groupe de clients</label>

                    <div
                      class="flex flex-col sm:flex-row relative mt-2 rounded-md shadow-sm"
                    >
                      <div class="flex">
                        <div class="relative">
                          <input
                            v-on:keyup.enter="
                              getHistorique(), (historiqueShow = true)
                            "
                            list="brow2"
                            v-model="Group"
                            placeholder="Sélectionner un groupe de clients"
                            style="width: 33rem"
                            class="block focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                          />

                          <datalist id="brow2" class="">
                            <option
                              v-for="(c, index) in Clients"
                              :key="index"
                              :value="c.Name"
                            ></option>
                          </datalist>

                          <div
                            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                          >
                            <svg
                              class="w-4 h-4 fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Tél n°1</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="tel"
                        v-model="telephone"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Email</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="email"
                        v-model="email"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Adresse</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model="Address"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Complément d'adresse</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model="Complement"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Code Postal</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model="PostalCode"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Ville</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model="City"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Pays</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="textfield"
                        v-model="Country"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="tab-pane active" id="tab-config">
                  <ul
                    class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 my-2"
                    id="tabs-tab"
                  >
                    <li>
                      <a
                        href="#tab-default"
                        aria-current="page"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                        >Général</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-config"
                        class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Config</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-coordinates"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Coordonnées</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-transport"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Transport</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-financial"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Finance</a
                      >
                    </li>
                  </ul>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Appliquer la T.V.A. ?</label>

                    <div class="flex relative mt-2 rounded-md shadow-sm">
                      <div
                        class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input type="radio" v-model="tva" value="true" />
                        <label class="pl-2 checkbox">Oui</label>
                      </div>

                      <div
                        class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input type="radio" v-model="tva" value="false" />
                        <label class="pl-2 checkbox">Non</label>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Autoriser à commander ?</label>

                    <div class="flex relative mt-2 rounded-md shadow-sm">
                      <div
                        class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input type="radio" v-model="commander" value="true" />
                        <label class="pl-2 checkbox">Oui</label>
                      </div>

                      <div
                        class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input type="radio" v-model="commander" value="false" />
                        <label class="pl-2 checkbox">Non</label>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Encours Max. autorisé</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="encoursmaxautorise"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Date du dernier paiement</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="datedudernierpaiement"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="tab-pane active" id="tab-coordinates">
                  <ul
                    class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 my-2"
                    id="tabs-tab"
                  >
                    <li>
                      <a
                        href="#tab-default"
                        aria-current="page"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                        >Général</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-config"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Config</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-coordinates"
                        class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Coordonnées</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-transport"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Transport</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-financial"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Finance</a
                      >
                    </li>
                  </ul>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Tél n°2</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="telephone2"
                        type="text"
                        class="w-full px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Fax</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="fax"
                        type="text"
                        class="w-full px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="tab-pane active" id="tab-transport">
                  <ul
                    class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 my-2"
                    id="tabs-tab"
                  >
                    <li>
                      <a
                        href="#tab-default"
                        aria-current="page"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                        >Général</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-config"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Config</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-coordinates"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Coordonnées</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-transport"
                        class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Transport</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-financial"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Finance</a
                      >
                    </li>
                  </ul>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Livré par transporteur ?</label>

                    <div class="flex relative mt-2 rounded-md shadow-sm">
                      <div
                        class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="radio"
                          v-model="livrepartransporteur"
                          value="true"
                        />
                        <label class="pl-2 checkbox">Oui</label>
                      </div>

                      <div
                        class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      >
                        <input
                          type="radio"
                          v-model="livrepartransporteur"
                          value="false"
                        />
                        <label class="pl-2 checkbox">Non</label>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Frais de port</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="fraisdeport"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Franco de port</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="francodeport"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="tab-pane active" id="tab-financial">
                  <ul
                    class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 my-2"
                    id="tabs-tab"
                  >
                    <li>
                      <a
                        href="#tab-default"
                        aria-current="page"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                        >Général</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-config"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Config</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-coordinates"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Coordonnées</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-transport"
                        class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Transport</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-financial"
                        class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                        >Finance</a
                      >
                    </li>
                  </ul>

                  <div class="container" v-if="professionnel">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">IBAN</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model="iban"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">SWIFT</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model="swift"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Nom de la banque</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model="nomdelabanque"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Moyen de paiement</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model="moyendepaiement"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Délai de paiement</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model="delaidepaiement"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">N° compte comptable</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model="ncomptecomptable"
                          type="text"
                          class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--Footer-->
              <div class="flex justify-end pt-2">
                <button
                  @click="openSave = false"
                  class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
                >
                  Fermer
                </button>
                <button
                  @click="
                    (openSave = false), saveDocument(), setClients(clientChosed)
                  "
                  class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                >
                  Enregistrer
                </button>
              </div>
            </div>

            <!--Créer un nouveau client-->
            <div class="tab-content" v-show="!isHidden" id="Créer">
              <div
                class="container mt-10 mb-2.5 font-medium text-xl border-solid border-b-2 border-blue-500 bg-blue-100"
              >
                <label>Créer un nouveau client</label>
              </div>

              <div class="tab-pane active" id="tab-default1">
                <ul
                  class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-2"
                  id="tabs-tab"
                >
                  <li>
                    <a
                      href="#tab-default1"
                      aria-current="page"
                      class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                      >Général</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-config1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Config</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-coordinates1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Coordonnées</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-transport1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Transport</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-financial1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Finance</a
                    >
                  </li>
                </ul>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Nom</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="nomduclient1"
                      type="text"
                      class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Professionnel ?</label>

                  <div class="flex relative mt-2 rounded-md shadow-sm">
                    <div
                      class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        @click="professionnel1 = true"
                        value="true"
                        data-val="true"
                        data-val-required="Ce champ est obligatoire."
                        id="CanLogin"
                        name="CanLogin"
                        data-enable="True"
                        data-for-field="CanLogin"
                      />
                      <label class="pl-2 checkbox">Oui</label>
                    </div>

                    <div
                      class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        @click="professionnel1 = false"
                        value="false"
                        id="CanLogin"
                        name="CanLogin"
                        data-enable="True"
                        data-for-field="CanLogin"
                      />
                      <label class="pl-2 checkbox">Non</label>
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Groupe de clients</label>

                  <div
                    class="flex flex-col sm:flex-row relative mt-2 rounded-md shadow-sm"
                  >
                    <div class="flex">
                      <div class="relative">
                        <input
                          v-on:keyup.enter="
                            getHistorique(), (historiqueShow = true)
                          "
                          list="brow5"
                          v-model="Group1"
                          placeholder="Sélectionner un groupe de clients"
                          style="width: 33rem"
                          class="block focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />

                        <datalist id="brow5" class="">
                          <option
                            v-for="(c, index) in Clients"
                            :key="index"
                            :value="c.Group.Name"
                          ></option>
                        </datalist>

                        <div
                          class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                        >
                          <svg
                            class="w-4 h-4 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Tél n°1</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="tel"
                      v-model="telephone1"
                      class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Email</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="email"
                      v-model="email1"
                      class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Adresse</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model="Address1"
                      class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Complément d'adresse</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model="Complement1"
                      class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Code Postal</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model="PostalCode1"
                      class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Ville</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model="City1"
                      class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Pays</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model="Country1"
                      class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane active" id="tab-config1">
                <ul
                  class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 my-2"
                  id="tabs-tab"
                >
                  <li>
                    <a
                      href="#tab-default1"
                      aria-current="page"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                      >Général</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-config1"
                      class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Config</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-coordinates1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Coordonnées</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-transport1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Transport</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-financial1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Finance</a
                    >
                  </li>
                </ul>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Appliquer la T.V.A. ?</label>

                  <div class="flex relative mt-2 rounded-md shadow-sm">
                    <div
                      class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input type="radio" v-model="tva1" value="true" />
                      <label class="pl-2 checkbox">Oui</label>
                    </div>

                    <div
                      class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input type="radio" v-model="tva1" value="false" />
                      <label class="pl-2 checkbox">Non</label>
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Autoriser à commander ?</label>

                  <div class="flex relative mt-2 rounded-md shadow-sm">
                    <div
                      class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input type="radio" v-model="commander1" value="true" />
                      <label class="pl-2 checkbox">Oui</label>
                    </div>

                    <div
                      class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input type="radio" v-model="commander1" value="false" />
                      <label class="pl-2 checkbox">Non</label>
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Encours Max. autorisé</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="encoursmaxautorise1"
                      type="text"
                      class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Date du dernier paiement</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="datedudernierpaiement1"
                      type="text"
                      class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane active" id="tab-coordinates1">
                <ul
                  class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 my-2"
                  id="tabs-tab"
                >
                  <li>
                    <a
                      href="#tab-default1"
                      aria-current="page"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                      >Général</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-config1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Config</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-coordinates1"
                      class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Coordonnées</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-transport1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Transport</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-financial1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Finance</a
                    >
                  </li>
                </ul>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Tél n°2</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="telephone21"
                      type="text"
                      class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Fax</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="fax1"
                      type="text"
                      class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane active" id="tab-transport1">
                <ul
                  class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 my-2"
                  id="tabs-tab"
                >
                  <li>
                    <a
                      href="#tab-default1"
                      aria-current="page"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                      >Général</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-config1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Config</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-coordinates1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Coordonnées</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-transport1"
                      class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Transport</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-financial1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Finance</a
                    >
                  </li>
                </ul>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Livré par transporteur ?</label>

                  <div class="flex relative mt-2 rounded-md shadow-sm">
                    <div
                      class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model="livrepartransporteur1"
                        value="true"
                      />
                      <label class="pl-2 checkbox">Oui</label>
                    </div>

                    <div
                      class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model="livrepartransporteur1"
                        value="false"
                      />
                      <label class="pl-2 checkbox">Non</label>
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Frais de port</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="fraisdeport1"
                      type="text"
                      class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Franco de port</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="francodeport1"
                      type="text"
                      class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane active" id="tab-financial1">
                <ul
                  class="nav flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 my-2"
                  id="tabs-tab"
                >
                  <li>
                    <a
                      href="#tab-default1"
                      aria-current="page"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-600"
                      >Général</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-config1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Config</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-coordinates1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Coordonnées</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-transport1"
                      class="border-b-blue-600 hover:border-transparent hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Transport</a
                    >
                  </li>
                  <li>
                    <a
                      href="#tab-financial1"
                      class="border-transparent bg-gray-100 border-x-0 border-t-0 border-b-2 border-transparent focus:border-transparent inline-block ml-4 mt-2 p-2 text-blue-600 font-semibold rounded-t-lg active dark:text-blue-500"
                      >Finance</a
                    >
                  </li>
                </ul>

                <div class="container" v-if="professionnel1">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">IBAN</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="iban1"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">SWIFT</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="swift1"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Nom de la banque</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="nomdelabanque1"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Moyen de paiement</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="moyendepaiement1"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Délai de paiement</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="delaidepaiement"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">N° compte comptable</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="ncomptecomptable1"
                        type="text"
                        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!--Footer-->
              <div class="flex justify-end pt-2">
                <button
                  @click="openSave = false"
                  class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
                >
                  Fermer
                </button>
                <button
                  @click="(openSave = false), saveDocument(), postClients()"
                  class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                >
                  Enregistrer 1
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-3 sm:flex-row-reverse">
      <div class="flex">
        <div class="relative">
          <select
            v-model="documentType"
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:bg-white"
          >
            <option value="Devis">Devis</option>
            <option value="Commande">Commande</option>
            <option value="Bon de livraison">Bon de livraison</option>
            <option value="Consigne">Consigne</option>
            <option value="Ordre de réparation">Ordre de réparation</option>
            <option value="Avoir">Avoir</option>
            <option value="Facturation">Facturation</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <button
          class="px-6 py-2 ml-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          @click="openSave = true"
        >
          Enregistrer
        </button>
      </div>
    </div>
    <!-- second select -->
    <div class="flex flex-col mt-3 sm:flex-row">
      <div class="flex">
        <div class="relative">
          <input
            v-on:keyup.enter="getHistorique(), (historiqueShow = true)"
            list="brow2"
            v-model="clientChosed"
            placeholder="Clients"
            class="block focus:outline-2 outline-sky-300 w-full py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
          />
          <datalist id="brow2">
            <option
              v-for="(c, index) in Clients"
              :key="index"
              :value="c.Name"
            ></option>
          </datalist>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- modal historique  -->
    <div
      v-if="historiqueShow"
      :class="`modal ${
        !historiqueShow && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="historiqueShow = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 78rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Historique</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="historiqueShow = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="flex flex-row mt-3">
            <label> n </label>
          </div>
          <div
            class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            style="height: 30vh"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr
                    v-for="(historiqueFile, index) in historique"
                    :key="index"
                    @click="
                      setHistoriqueFile(historiqueFile.Articles),
                        (historiqueShow = false)
                    "
                    style="cursor: pointer"
                    class="hover:bg-blue-50"
                  >
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      {{ historiqueFile.Type }} N° {{ historiqueFile.Numero }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Date : {{ historiqueFile.Date }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Client : {{ historiqueFile.Client }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Crée par : {{ historiqueFile.Name }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix Total :
                      {{ parseFloat(historiqueFile.TotalPrice).toFixed(2) }} €
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Desc : {{ historiqueFile.Note }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Validation :
                      <span
                        class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                        v-if="historiqueFile.IsProcessed === true"
                      >
                        <span class="material-icons-outlined">
                          done
                        </span></span
                      >
                      <span
                        class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                        v-else
                      >
                        <span class="material-icons-outlined">close</span></span
                      >
                    </th>

                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="historiqueShow = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- fin modal historique -->

    <div class="flex flex-col mt-2">
      <div class="relative block mt-2 sm:mt-0">
        <input
          v-on:keyup.enter="(showArticleList = true), getOneArticle()"
          v-model="articleSearch"
          placeholder="Entrez un code article"
          class="block focus:outline-2 outline-sky-300 w-full py-2 pl-8 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
        />
        <ul
          v-show="showArticleList"
          class="absolute min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <!-- Table pour trouver un produit -->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equipe./fourn.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  P.P.C.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(found, index) in foundArticle"
                :key="index"
                @click="handlerArticle(found)"
                class="hover:bg-gray-50"
                style="cursor: pointer"
              >
                <td
                  class="px-6 py-8 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ found.Ref_fournisseur }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ found.Code_marque }}
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    {{ found.Description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ found.Qte_mini }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  0 €
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(found.Prix_euro).toFixed(2) }} €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  0 %
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(found.Prix_euro).toFixed(2) }} €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(found.Prix_euro).toFixed(2) }} €
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <button
                    class="px-6 py-2 mt-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                    @click="showArticleList = false"
                  >
                    Fermer
                  </button>
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>
          </table>
          <!-- Fin Table pour trouver un produit -->
        </ul>

        <div
          class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
        >
          <svg
            class="w-4 h-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Ref
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Equipe./fourn.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.P.C.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white tableBODY">
              <tr
                v-for="(articleAct, index) in actionArticle"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="px-6 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ articleAct.Ref_fournisseur }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ articleAct.Code_marque }}
                  </div>
                  <!-- <div class="text-sm leading-5 text-gray-500">
                    {{ articleAct.Description }}
                  </div> -->
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <input
                    class="focus:outline-2 outline-sky-300"
                    style="width: 50%"
                    type="number"
                    :placeholder="articleAct.Qte_mini"
                  />
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  0,00 €
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <input
                    class="focus:outline-2 outline-sky-300"
                    type="number"
                    style="width: 30%"
                    placeholder="0"
                    step="5"
                  />
                  %
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                </td>
                <td
                  class="px-8 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 rounded-full"
                    style="cursor: pointer"
                    @click="suppactionArticle(articleAct._id)"
                  >
                    <span class="material-icons-round"> backspace </span>
                  </span>
                </td>
              </tr>
            </tbody>
            <thead class="tableHEADTR">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  {{ setQuantity }} pcs
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total : {{ parseFloat(setTotal).toFixed(2) }} € HT
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total : {{ parseFloat(setTotalTTC).toFixed(2) }} € TTC
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
    <!-- <button
      class="px-6 py-2 mt-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
    >
      Encaisser
    </button> -->
  </div>
</template>
<script lang="js">
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      openSave:false,
      showArticleList:false,
      Clients:[],
      clientChosed:'',
      articleSearch:'',
      foundArticle:[],
      documentType:'',
      titreDocument:'',
      noteDocument:"",
      historique:[],
      historiqueShow:false,
      client:false,
      professionnel:false,
      professionnel1:false,
      isHidden:false,
      nomduclient: "",
      Group: "",
      Address: "",
      Country: "",
      City: "",
      PostalCode: "",
      Complement: "",
      telephone: "",
      email: "",
      tva: "",
      commander: "",
      encoursmaxautorise: "",
      datedudernierpaiement: "",
      telephone2: "",
      fax: "",
      livrepartransporteur: "",
      fraisdeport: "",
      francodeport: "",
      iban: "",
      swift: "",
      nomdelabanque: "",
      moyendepaiement: "",
      delaidepaiement: "",
      ncomptecomptable: "",
      nomduclient1: "",
      Group1: "",
      Address1: "",
      Country1: "",
      City1: "",
      PostalCode1: "",
      Complement1: "",
      telephone1: "",
      email1: "",
      tva1: "",
      commander1: "",
      encoursmaxautorise1: "",
      datedudernierpaiement1: "",
      telephone21: "",
      fax1: "",
      livrepartransporteur1: "",
      fraisdeport1: "",
      francodeport1: "",
      iban1: "",
      swift1: "",
      nomdelabanque1: "",
      moyendepaiement1: "",
      delaidepaiement1: "",
      ncomptecomptable1: "",
    }
  },
  computed:{
    ...mapGetters(["actionArticle", "user"]),
    setTotal(){
      var total = 0.00;
      this.actionArticle.forEach(element => {
        total = total + parseFloat(element.Prix_euro);
      });
      return total;
    },
    setTotalTTC(){
      return this.setTotal + (this.setTotal * 0.2);

    },
    setQuantity(){
      var qty = 0;
      this.actionArticle.forEach(element => {
        qty = qty + parseFloat(element.Qte_multiple);
      });
      return qty;
    }
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "suppactionArticle",
      "clearactionArticle",
      "updateactionArticle",
    ]),
    handlerArticle(data) {
      this.addactionArticle(data);
      this.showArticleList = false;
      // this.addProduitComponent = false;
    },
    postClients(){
        axios
          .post(`${process.env.VUE_APP_API}/client`, {
            Id1: this.Id1,
            RowVersion1: this.RowVersion1,
            StringValue1: this.StringValue1,
            nomduclient1: this.nomduclient1,
            professionnel1: this.professionnel1,
            Group1: {
              FreeOfCharge1: this.FreeOfCharge1,
              Name1: this.Group1,
              Id1: this.Id1,
            },
            Name1: this.Name1,
            Number1: this.Number1,
            Address1: {
              Country1: {
                Name1: this.Country1,
                Id1: this.Id1,
              },
              City1: {
                Name1: this.City1,
                Id1: this.Id1,
              },
              PostalCode1: this.PostalCode1,
              Id1: this.Id1,
              Complement1: {
                Name1: this.Complement1,
                Id1: this.Id1,
              },
            },
            Archived1: this.Archived1,
            CanOrder1: this.CanOrder1,
            telephone1: this.telephone1,
            email1: this.email1,
            tva1: this.tva1,
            commander1: this.commander1,
            encoursmaxautorise1: this.encoursmaxautorise1,
            datedudernierpaiement1: this.datedudernierpaiement1,
            telephone21: this.telephone21,
            fax1: this.fax1,
            livrepartransporteur1: this.livrepartransporteur1,
            fraisdeport1: this.fraisdeport1,
            francodeport1: this.francodeport1,
            iban1: this.iban1,
            swift1: this.swift1,
            nomdelabanque1: this.nomdelabanque1,
            moyendepaiement1: this.moyendepaiement1,
            delaidepaiement1: this.delaidepaiement1,
            ncomptecomptable1: this.ncomptecomptable1,
          })
          .then(() => {
                this.getClients();
                this.open = false;
              });
        },
    setClients(idclient){
        axios
          .put(`${process.env.VUE_APP_API}/${idclient}`, {
            Id: this.Id,
            RowVersion: this.RowVersion,
            StringValue: this.StringValue,
            nomduclient: this.nomduclient,
            professionnel: this.professionnel,
            Group: {
              FreeOfCharge: this.FreeOfCharge,
              Name: this.Group,
              Id: this.Id,
            },
            Name: this.Name,
            Number: this.Number,
            Address: {
              Country: {
                Name: this.Country,
                Id: this.Id,
              },
              City: {
                Name: this.City,
                Id: this.Id,
              },
              PostalCode: this.PostalCode,
              Id: this.Id,
              Complement: {
                Name: this.Complement,
                Id: this.Id,
              },
            },
            Archived: this.Archived,
            CanOrder: this.CanOrder,
            telephone: this.telephone,
            email: this.email,
            tva: this.tva,
            commander: this.commander,
            encoursmaxautorise: this.encoursmaxautorise,
            datedudernierpaiement: this.datedudernierpaiement,
            telephone2: this.telephone2,
            fax: this.fax,
            livrepartransporteur: this.livrepartransporteur,
            fraisdeport: this.fraisdeport,
            francodeport: this.francodeport,
            iban: this.iban,
            swift: this.swift,
            nomdelabanque: this.nomdelabanque,
            moyendepaiement: this.moyendepaiement,
            delaidepaiement: this.delaidepaiement,
            ncomptecomptable: this.ncomptecomptable,
          })
          .then(() => {
                this.getClients();
                this.open = false;
              });
        },
    getClients(){
      axios.get(`${process.env.VUE_APP_API}/client`).then((response)=>{
        this.Clients = response.data;
      })
    },
    getOneArticle(){
      if(this.articleSearch != ''){
        axios.post(`${process.env.VUE_APP_API}/article/getone`,{Ref_fournisseur:this.articleSearch}).then((response)=>{
          this.foundArticle = response.data;
        });
      }else{
        // this.getArticles(10,10);

      }
    },
    saveDocument(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();

      today = dd + '/' + mm + '/' + yyyy + ' ' + hour + ':' + minutes + ':' + seconds;
      switch (this.documentType) {
        case 'Devis':
          axios.post(`${process.env.VUE_APP_API}/devis`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Commande':
          axios.post(`${process.env.VUE_APP_API}/commandes`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Bon de livraison':
          axios.post(`${process.env.VUE_APP_API}/bdl`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Consigne':
          axios.post(`${process.env.VUE_APP_API}/consigne`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Or':
          axios.post(`${process.env.VUE_APP_API}/or`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Avoir':
          axios.post(`${process.env.VUE_APP_API}/avoir`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Facturation':
          axios.post(`${process.env.VUE_APP_API}/facture`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        default:
          break;
      }
      axios.post(`${process.env.VUE_APP_API}/comptoir`,{Type:this.documentType, Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
      this.clearactionArticle();
      this.clientChosed = '';
      this.articleSearch = '';
    },
    getHistorique(){
      axios
        .post(`${process.env.VUE_APP_API}/comptoir/getone`,{uid:this.clientChosed})
        .then((response) => {
          this.historique = response.data;
        });
    },
    setHistoriqueFile(historiquedata){
      this.updateactionArticle(historiquedata);
    }
  },
  mounted() {
    this.getClients();
  },
}
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
.tableTDTH {
  text-align: left;
  padding: 8px;
}

.tableBODY {
  display: block;
  height: 51vh;
  overflow: auto;
}
.tableHEADTR {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
