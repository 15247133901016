<template>
  <div class="flex flex-col space-y-1">
    <div class="text-sm">
      <span>Prix Brut : </span>
      <span>{{ prixBrut }} €</span>
    </div>
    <div>
      <span class="text-sm">Prix Net : </span>
      <span class="font-bold text-xl">{{ prixNet }} €</span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      prixNet: "--",
      prixBrut: "--",
      userGroupClient: "",
    };
  },
  props: ["article", "marque", "Prefixe_tarif", "qty"],
  computed: {
    ...mapGetters(["user", "plateform", "tabClient"]),
  },
  methods: {
    async emitPrix() {
      await this.$emit("close", this.prixNet);
    },
    async emitPrixNet() {
      await this.$emit("prix", this.prixNet);
    },
  },
  async mounted() {
    let plateform_mere = this.user.plateform;
    if (this.plateform.plateform_mere) {
      plateform_mere = this.plateform.plateform_mere;
    }
    await axios
      .post(`${process.env.VUE_APP_API}/catalogue/getPrixDispo`, {
        Ref_fournisseur: this.article,
        Code_marque: this.marque,
        Prefixe_tarif: this.Prefixe_tarif,
        plateform: plateform_mere,
        client: this.user.proprietaire,
        groupClient: this.tabClient.Group.Name,
        Quantity: this.qty,
      })
      .then((response) => {
        response.data.prixNet != "--"
          ? (this.prixNet = parseFloat(response.data.prixNet).toFixed(2))
          : (this.prixNet = "--");

        response.data.prixBrut != "--"
          ? (this.prixBrut = parseFloat(response.data.prixBrut).toFixed(2))
          : (this.prixBrut = "--");
      });
    this.emitPrix();
    this.emitPrixNet();
  },
};
</script>
<style></style>
