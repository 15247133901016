import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "../store";
import Dashboard from "../views/Dashboard.vue";
import LoginPage from "../views/LoginPage.vue";
// import NotFound from "../views/NotFound.vue";
import Articles from "../views/Articles.vue";
import Fournisseurs from "../views/Fournisseurs.vue";
// import Preparation from "../views/Preparation.vue";
import Promotions from "../views/Promotions.vue";
import Enlevement from "../views/Enlevement.vue";
import Clients from "../views/Clients.vue";
import Precommande from "../views/Precommande.vue";
import Statistiques from "../views/Statistiques.vue";
import Base from "../views/Base.vue";
import Caisse from "../views/Caisse.vue";
import Utilisateurs from "../views/Utilisateurs.vue";
import MouvementStock from "../views/Articles/MouvementStock.vue";
import CatalogueDePneus from "../views/Articles/CatalogueDePneus.vue";
import FamilleArticle from "../views/Articles/Configuration/familleArticle.vue";
import SousFamilleArticle from "../views/Articles/Configuration/sousfamilleArticle.vue";
import actionClient from "../views/actionClient.vue";
import Devis from "../views/Clients/Devis.vue";
import Commandes from "../views/Clients/Commandes.vue";
import Bdl from "../views/Clients/Bdl.vue";
import Consigne from "../views/Clients/Consigne.vue";
import Or from "../views/Clients/Or.vue";
import Avoir from "../views/Clients/Avoir.vue";
import Facture from "../views/Clients/Facture.vue";
import Commandesf from "../views/Commandes/Commandes.vue";
import ListeCommandes from "../views/Commandes/listeCommandes.vue";
import TecDoc from "../views/Catalogue/Tecdoc.vue";
import TecDocSearch from "../views/Catalogue/TecDocSearch.vue";
import HistoriquePlaque from "../views/historiquePlaque.vue";
import Profil from "../views/Profil.vue";
import Retour from "../views/Retour.vue";
import CarCat from "../views/Catalogue/CarCat.vue";
import catalogueHub from "../views/Catalogue/catalogueHub.vue";
import catalogueTecDoc from "../views/Catalogue/catalogueTecDoc.vue";
import articleInfo from "../components/articleInfo/articleInfo.vue";
import SearchArticle from "../views/SearchArticle.vue";
import devisClient from "../views/devisClient/devisClient.vue";
import listeDevisClient from "../views/devisClient/listeDevisClient.vue";
import listeClient from "../views/listeClient.vue";
import DemandeRetour from "../views/DemandeRetour.vue";
import panierRetour from "../views/panierRetour.vue";
import Carousel from "../components/Carousel.vue";
import PromoMosaique from "../views/PromoMosaique.vue";
import historiqueArticle from "../views/historiqueArticle.vue";
import searchArticleExactBis from "../views/searchArticleExactBis.vue";
import searchArticleVH from "../views/searchArticleVH.vue";
import searchArticleNew from "../views/searchArticleNew.vue";
import searchArticleExact from "../views/searchArticleExact.vue";
import catalogueHubNew from "../views/Catalogue/catalogueHubNew.vue";
import catalogueHubUniversel from "../views/Catalogue/catalogueHubUniversel.vue";
import catalogueTecDocNew from "../views/Catalogue/catalogueTecDocNew.vue";
import catalogueTecDocSousFamille from "../views/Catalogue/catalogueTecDocSousFamille.vue";
import TableauRemise from "../views/TableauRemise.vue";
import searchArticleDetail from "../views/searchArticleDetail.vue";
import searchArticleDetailBis from "../views/searchArticleDetailBis.vue";
import searchArticleDetailBisForceOE from "../views/searchArticleDetailBisForceOE.vue";
import paniersClient from "../views/paniersClient.vue";
import detailPanierClient from "../views/detailPanierClient.vue";

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: { layout: "empty" },
  },
  {
    path: "/profil",
    name: "Profil",
    component: Profil,
  },
  {
    path: "/searchArticleVH",
    name: "searchArticleVH",
    component: searchArticleVH,
  },
  {
    path: "/searchArticleDetail",
    name: "searchArticleDetail",
    component: searchArticleDetail,
  },
  {
    path: "/searchArticleDetailBis",
    name: "searchArticleDetailBis",
    component: searchArticleDetailBis,
  },
  {
    path: "/searchArticleDetailBisForceOE",
    name: "searchArticleDetailBisForceOE",
    component: searchArticleDetailBisForceOE,
  },
  {
    path: "/searchArticleExactBis",
    name: "searchArticleExactBis",
    component: searchArticleExactBis,
  },
  {
    path: "/TableauRemise",
    name: "TableauRemise",
    component: TableauRemise,
  },
  {
    path: "/searchArticleNew",
    name: "searchArticleNew",
    component: searchArticleNew,
  },
  {
    path: "/searchArticleExact",
    name: "searchArticleExact",
    component: searchArticleExact,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/catalogueHubNew",
    name: "catalogueHubNew",
    component: catalogueHubNew,
  },
  {
    path: "/catalogueHubUniversel",
    name: "catalogueHubUniversel",
    component: catalogueHubUniversel,
  },
  {
    path: "/catalogueTecDocNew",
    name: "catalogueTecDocNew",
    component: catalogueTecDocNew,
  },
  {
    path: "/catalogueTecDocSousFamille",
    name: "catalogueTecDocSousFamille",
    component: catalogueTecDocSousFamille,
  },
  {
    path: "/articles",
    name: "Articles",
    component: Articles,
  },
  {
    path: "/fournisseurs",
    name: "Fournisseurs",
    component: Fournisseurs,
  },
  {
    path: "/catalogueTecDoc",
    name: "catalogueTecDoc",
    component: catalogueTecDoc,
  },
  {
    path: "/catalogueHubNew",
    name: "catalogueHub",
    component: catalogueHub,
  },
  {
    path: "/searchArticle",
    name: "SearchArticle",
    component: SearchArticle,
  },
  {
    path: "/articleInfo",
    name: "articleInfo",
    component: articleInfo,
  },
  // {
  //   path: "/preparation",
  //   name: "Preparation",
  //   component: Preparation,
  // },
  {
    path: "/promotions",
    name: "Promotions",
    component: Promotions,
  },
  {
    path: "/enlevement",
    name: "Enlevement",
    component: Enlevement,
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
  },
  {
    path: "/precommande",
    name: "Precommande",
    component: Precommande,
  },
  {
    path: "/statistiques",
    name: "Statistiques",
    component: Statistiques,
  },
  {
    path: "/base",
    name: "Base",
    component: Base,
  },
  {
    path: "/caisse",
    name: "Caisse",
    component: Caisse,
  },
  {
    path: "/utilisateurs",
    name: "Utilisateurs",
    component: Utilisateurs,
  },
  {
    path: "/cataloguedepneus",
    name: "CatalogueDePneus",
    component: CatalogueDePneus,
  },
  {
    path: "/mouvementstock",
    name: "MouvementStock",
    component: MouvementStock,
  },
  {
    path: "/actionclient",
    name: "actionClient",
    component: actionClient,
  },
  {
    path: "/famillearticle",
    name: "FamilleArticle",
    component: FamilleArticle,
  },
  {
    path: "/tecdoc",
    name: "TecDoc",
    component: TecDoc,
  },
  {
    path: "/tecdocsearch",
    name: "TecDocSearch",
    component: TecDocSearch,
  },
  {
    path: "/sousfamillearticle",
    name: "SousFamilleArticle",
    component: SousFamilleArticle,
  },
  {
    path: "/devis",
    name: "Devis",
    component: Devis,
  },
  {
    path: "/commandes",
    name: "Commandes",
    component: Commandes,
  },
  {
    path: "/bdl",
    name: "Bdl",
    component: Bdl,
  },
  {
    path: "/consigne",
    name: "Consigne",
    component: Consigne,
  },
  {
    path: "/or",
    name: "Or",
    component: Or,
  },
  {
    path: "/avoir",
    name: "Avoir",
    component: Avoir,
  },
  {
    path: "/facture",
    name: "Facture",
    component: Facture,
  },
  {
    path: "/commandesf",
    name: "CommandesF",
    component: Commandesf,
  },
  {
    path: "/listecommandes",
    name: "ListeCommandes",
    component: ListeCommandes,
  },
  {
    path: "/retour",
    name: "Retour",
    component: Retour,
  },
  {
    path: "/historiqueplaque",
    name: "HistoriquePlaque",
    component: HistoriquePlaque,
  },
  {
    path: "/carcat",
    name: "CarCat",
    component: CarCat,
  },
  {
    path: "/devisClient",
    name: "devisClient",
    component: devisClient,
  },
  {
    path: "/listeDevisClient",
    name: "listeDevisClient",
    component: listeDevisClient,
  },
  {
    path: "/listeClient",
    name: "listeClient",
    component: listeClient,
  },
  {
    path: "/demandeRetour",
    name: "DemandeRetour",
    component: DemandeRetour,
  },
  {
    path: "/panierRetour",
    name: "panierRetour",
    component: panierRetour,
  },
  {
    path: "/carousel",
    name: "Carousel",
    component: Carousel,
  },
  { path: "/:pathMatch(.*)*", component: Dashboard },
  {
    path: "/PromoMosaique",
    name: "PromoMosaique",
    component: PromoMosaique,
  },
  {
    path: "/historiqueArticle",
    name: "historiqueArticle",
    component: historiqueArticle,
  },
  {
    path: "/paniersClient",
    name: "paniersClient",
    component: paniersClient,
  },
  {
    path: "/detailPanierClient",
    name: "detailPanierClient",
    component: detailPanierClient,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      store.dispatch("setUser", user.uid);
      store.dispatch("getTabClient");
      next();
    } else {
      if (to.path !== "/login") {
        store.dispatch("deconnecter");
        next({ name: "LoginPage" });
      } else {
        next();
      }
    }
  });
});

export default router;
