<template>
  <div class="flex flex-row p-2 bg-white rounded shadow w-full h-fit">
    <div class="hidden md:flex my-auto bg-blue-100">
      <input
        type="checkbox"
        disabled
        v-if="compareTabLength > 2 && compareTabLength <= 3 && !checkbox_value"
        v-model="checkbox_value"
        @change="$emit('checkedArticle')"
      />
      <input
        type="checkbox"
        v-else
        v-model="checkbox_value"
        @change="$emit('checkedArticle')"
      />
    </div>
    <div class="hidden md:flex md:flex-col my-auto space-y-2 w-10">
      <span
        class="material-icons-round cursor-pointer text-blue-500"
        @click="
          searchRefFournisseurGen({
            Ref_fournisseur: Ref_fournisseur,
          })
        "
      >
        sync
      </span>
    </div>
    <div class="w-2/12">
      <img
        v-if="Images.length > 0 && Images[0].imageURL200"
        :src="Images[0].imageURL200"
        alt="image"
        class="w-full max-h-full mx-auto w-16 h-16 md:w-24 md:h-24 lg:w-34 lg:h-34"
      />
      <img
        v-else
        src="@/assets/not-found.png"
        alt="image"
        class="w-full max-h-full mx-auto w-16 h-16 md:w-24 md:h-24 lg:w-34 lg:h-34"
      />
    </div>
    <div class="flex flex-col w-2/5 md:w-2/12 text-sm md:text-base">
      <div
        class="w-fit text-blue-500 cursor-pointer"
        @click="
          searchRefFournisseurGen({
            Ref_fournisseur: Ref_fournisseur,
          })
        "
      >
        {{ Ref_fournisseur }}
      </div>
      <div class="flex lg:hidden font-bold">{{ Code_marque }}</div>
      <div class="flex md:hidden">
        <stockvue
          class="my-auto"
          :Stock="tmp_stock"
          :Ref_fournisseur="Ref_fournisseur"
          :Code_marque="Code_marque"
        />
      </div>
    </div>
    <div class="w-1/12 hidden lg:flex lg:flex-col">
      <div class="font-bold">{{ Code_marque }}</div>
      <div></div>
    </div>
    <div class="w-2/12 hidden lg:flex">{{ Description }}</div>
    <div class="w-3/12 lg:3/12 hidden md:flex md:flex-col md:space-y-2">
      <!-- <span v-if="Systeme.formattedValue">{{ Systeme.formattedValue }}</span>
      <span v-if="Location.formattedValue">{{ Location.formattedValue }}</span> -->
      <button
        class="flex flex-row items-center space-x-1 mx-auto text-sm text-white bg-blue-500 py-1 px-2 rounded shadow"
        @click="
          searchRefFournisseurGen({
            Ref_fournisseur: Ref_fournisseur,
          })
        "
      >
        <span>Voir nos propositions</span>
        <span class="material-icons-round"> search </span>
      </button>
    </div>
    <div class="w-2/12 hidden md:flex">
      <stockvue
        class="my-auto"
        :Stock="tmp_stock"
        :Ref_fournisseur="Ref_fournisseur"
        :Code_marque="Code_marque"
      />
    </div>
    <div class="flex flex-col w-1/5 md:w-1/12 text-sm md:text-base p-2">
      <div class="m-auto">
        <prixvue :article="article" :Prix="Prix" />
      </div>
      <div class="flex md:hidden m-auto">
        <!-- <paniervue
          :article="article"
          :quantity="quantity"
          @updateQuantity="checkQuantity"
        /> -->
      </div>
    </div>
    <div class="w-1/12 lg:1/12 hidden md:flex">
      <div class="m-auto">
        <!-- <paniervue
          :article="article"
          :quantity="quantity"
          @updateQuantity="checkQuantity"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import stockvue from "@/components/Article/stock.vue";
import prixvue from "@/components/Article/prix.vue";
// import paniervue from "@/components/Article/panier.vue";
import { checkStock } from "@/hooks/stock/stock.ts";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "oeCatalogue",
  data() {
    return {
      tmp_stock: {},
      checkbox_value: false,
    };
  },
  props: {
    article: { type: Object, default: () => ({}) },
    Ref_fournisseur: String,
    Description: String,
    Code_marque: String,
    mfrId: Number,
    Images: Array,
    Location: Object,
    Systeme: Object,
    Stock: {
      type: Object,
      default: () => ({
        state: "En rupture",
        stock: 0,
        stockFournisseur: 0,
        adresse: "",
        min: 0,
        max: 0,
        filliale: [],
      }),
    },
    Prix: {
      type: Object,
      default: () => ({
        promo: false,
        prix_achat: 0.0,
        prix_brut: 0.0,
        prix_net: 0.0,
      }),
    },
    quantity: Number,
    compareTabLength: {
      type: Number,
      default: 0,
    },
  },
  components: {
    stockvue,
    prixvue,
    // paniervue,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["searchRefFournisseurGen"]),
    async checkQuantity(n) {
      this.tmp_stock = await checkStock(
        this.user.plateform,
        this.article.Ref_fournisseur,
        this.article._id,
        this.article.Code_marque,
        this.article.Prefixe_tarif,
        n,
        this.user._id
      );
    },
  },
  mounted() {
    this.tmp_stock = this.Stock;
  },
};
</script>
<style></style>
