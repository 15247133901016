<template>
  <div>
    <ul class="flex flex-col align-center space-y-20">
      <!-- INFORMATIONS GENERALES -->
      <li
        style="width: 80rem"
        class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
      >
        <div class="flex justify-between hover:text-blue-400">
          <a class="text-xl"> Informations générales </a>
        </div>
        <transition>
          <div
            class="bloc-couleur couleur-remplissage"
            id="informationsgenerales"
          >
            <!-- <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Numéro client</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  v-model="numeroParticulier"
                  type="text"
                  ref="numeroParticulier"
                  @keydown.enter="this.$refs.nomParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div> -->

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Nom</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  v-model="nomParticulier"
                  type="text"
                  ref="nomParticulier"
                  @keydown.enter="this.$refs.adresseParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  type="textfield"
                  v-model="adresseParticulier"
                  ref="adresseParticulier"
                  @keydown.enter="this.$refs.complementParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Complément d'adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  type="textfield"
                  v-model="complementParticulier"
                  ref="complementParticulier"
                  @keydown.enter="this.$refs.codepostalParticulier.focus()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Code Postal</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="codepostalParticulier"
                    ref="codepostalParticulier"
                    @keydown.enter="this.$refs.villeParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Ville</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="villeParticulier"
                    ref="villeParticulier"
                    @keydown.enter="this.$refs.paysParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label for="paysParticulier" class="text-xs">Pays</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <select
                    name="paysParticulier"
                    v-model="paysParticulier"
                    ref="paysParticulier"
                    @keydown.enter="this.$refs.telephoneParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                  >
                    <option value="ALLEMAGNE">ALLEMAGNE</option>
                    <option value="AUTRICHE">AUTRICHE</option>
                    <option value="BELGIQUE">BELGIQUE</option>
                    <option value="ESPAGNE">ESPAGNE</option>
                    <option selected value="FRANCE">FRANCE</option>
                    <option value="ITALIE">ITALIE</option>
                    <option value="LUXEMBOURG">LUXEMBOURG</option>
                    <option value="MAROC">MAROC</option>
                    <option value="PAYS-BAS">PAYS-BAS</option>
                    <option value="SUISSE">SUISSE</option>
                    <option value="TURQUIE">TURQUIE</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Téléphone</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="tel"
                    v-model="telephoneParticulier"
                    ref="telephoneParticulier"
                    @keydown.enter="this.$refs.emailParticulier.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Email</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="emailParticulier"
                    v-model="emailParticulier"
                    ref="emailParticulier"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </li>
    </ul>

    <!--Footer-->
    <div class="flex justify-end pt-2">
      <button
        @click="close()"
        class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
      >
        Fermer
      </button>
      <button
        @click="saveClient()"
        class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      >
        Ajouter
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      professionnelParticulier: false,
      //   groupeClient: "T1",
      numeroParticulier: "",
      nomParticulier: "",
      adresseParticulier: "",
      complementParticulier: "",
      codepostalParticulier: "",
      villeParticulier: "",
      paysParticulier: "FRANCE",
      telephoneParticulier: "",
      emailParticulier: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  props: ["client"],
  methods: {
    saveClient() {
      axios
        .post(`${process.env.VUE_APP_API}/clientSpaceClient`, {
          professionnel: this.professionnelParticulier,
          Numero: this.numeroParticulier,
          plateform: this.user.plateform,
          Owner: this.user.proprietaire,
          Name: this.nomParticulier,
          Number: this.Numero,
          Adresse: this.adresseParticulier,
          Country: this.paysParticulier,
          City: this.villeParticulier,
          PostalCode: this.codepostalParticulier,
          Complement: this.complementParticulier,
          telephone: this.telephoneParticulier,
          email: this.emailParticulier,
        })
        .then(() => {
          this.toast.success("Client ajouté !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.emitClient();
          this.emitClientInfo();
          this.emitDataClient();
          this.close();
        });
    },
    close() {
      this.$emit("close");
    },
    emitClient() {
      this.$emit("client", this.nomParticulier);
    },
    emitClientInfo() {
      this.$emit("clientInfos", {
        Numero: this.numeroParticulier,
        Adresse: this.adresseParticulier,
        City: this.villeParticulier,
        Complement: this.complementParticulier,
        Country: this.paysParticulier,
        Name: this.nomParticulier,
        Owner: this.user.proprietaire,
        PostalCode: this.codepostalParticulier,
        email: this.emailParticulier,
        plateform: this.user.plateform,
        professionnel: this.professionnelParticulier,
        telephone: this.telephoneParticulier,
      });
    },
    emitDataClient() {
      this.$emit("isDataClient");
    },
  },
  mounted() {
    this.nomParticulier = this.client;
  }
};
</script>
