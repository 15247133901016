<template>
  <div>
    <ul class="flex flex-col align-center space-y-20">
      <!-- INFORMATIONS GENERALES -->
      <li
        style="width: 80rem"
        class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
      >
        <div class="flex justify-between hover:text-blue-400">
          <a class="text-xl"> Informations générales </a>
        </div>
        <transition>
          <div
            class="border-b border-gray-500 bloc-couleur couleur-remplissage"
            id="informationsgenerales"
          >
            <!-- <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Numéro client</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  v-model="clientChoosedTab.Numero"
                  type="text"
                  ref="numeroParticulier"
                  @keydown.enter="this.$refs.nomParticulier.focus()"
                  @input="saveChange(), emitClient()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div> -->

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Nom</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  v-model="clientChoosedTab.Name"
                  type="text"
                  ref="nomParticulier"
                  @keydown.enter="this.$refs.adresseParticulier.focus()"
                  @input="saveChange(), emitClient()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  type="textfield"
                  v-model="clientChoosedTab.Adresse"
                  ref="adresseParticulier"
                  @keydown.enter="this.$refs.complementParticulier.focus()"
                  @input="saveChange()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Complément d'adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input
                  type="textfield"
                  v-model="clientChoosedTab.Complement"
                  ref="complementParticulier"
                  @keydown.enter="this.$refs.codepostalParticulier.focus()"
                  @input="saveChange()"
                  class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Code Postal</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="clientChoosedTab.PostalCode"
                    ref="codepostalParticulier"
                    @keydown.enter="this.$refs.villeParticulier.focus()"
                    @input="saveChange()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Ville</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="clientChoosedTab.City"
                    ref="villeParticulier"
                    @keydown.enter="this.$refs.paysParticulier.focus()"
                    @input="saveChange()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label for="paysParticulier" class="text-xs">Pays</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <select
                    name="paysParticulier"
                    v-model="clientChoosedTab.Country"
                    ref="paysParticulier"
                    @keydown.enter="this.$refs.telephoneParticulier.focus()"
                    @input="saveChange()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                  >
                    <option value="ALLEMAGNE">ALLEMAGNE</option>
                    <option value="AUTRICHE">AUTRICHE</option>
                    <option value="BELGIQUE">BELGIQUE</option>
                    <option value="ESPAGNE">ESPAGNE</option>
                    <option selected value="FRANCE">FRANCE</option>
                    <option value="ITALIE">ITALIE</option>
                    <option value="LUXEMBOURG">LUXEMBOURG</option>
                    <option value="MAROC">MAROC</option>
                    <option value="PAYS-BAS">PAYS-BAS</option>
                    <option value="SUISSE">SUISSE</option>
                    <option value="TURQUIE">TURQUIE</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Téléphone</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="tel"
                    v-model="clientChoosedTab.telephone"
                    ref="telephoneParticulier"
                    @keydown.enter="this.$refs.emailParticulier.focus()"
                    @input="saveChange()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Email</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="emailParticulier"
                    v-model="clientChoosedTab.email"
                    ref="emailParticulier"
                    @input="saveChange()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user", "clientChoosedTab"]),
  },
  methods: {
    saveChange() {
      axios
        .put(
          `${process.env.VUE_APP_API}/clientSpaceClient/modifyClient/${this.clientChoosedTab._id}`,
          {
            data: this.clientChoosedTab,
          }
        )
        .then(() => {
          this.emitClientInfo();
        });
    },
    emitClient() {
      this.$emit("client", this.clientChoosedTab.Name);
    },
    emitClientInfo() {
      this.$emit("clientInfos", this.clientChoosedTab);
    },
  },
};
</script>
