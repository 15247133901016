<template>
  <div>
    <div
      class="flex flex-row items-center justify-between p-2 bg-white border border-gray-200 rounded w-36 md:w-44 cursor-pointer"
      @click="show = !show"
    >
      <span> {{ text }} </span>
      <span class="material-icons-round"> expand_more </span>
    </div>
    <div
      v-show="show"
      class="absolute z-20 p-1 rounded-b shadow-md bg-white w-36 md:w-44 transition-transform"
    >
      <div
        class="flex flex-row space-x-2 items-center"
        v-for="(item, indexItem) in filter_list"
        :key="indexItem"
      >
        <input
          :id="indexItem"
          type="checkbox"
          @change="$emit('manageFilter', item)"
        />
        <label :for="indexItem">{{ item }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "filterButton",
  props: {
    text: {
      type: String,
      default: "Valider",
    },
    filter_list: Array,
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>
<style></style>
