import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import router from "../router/index.js";
import { prepareArticleClient } from "@/hooks/searchEngine/article.ts";

export default createStore({
  state: {
    isOpen: false,
    generalLoading: false,
    auth: false,
    caisse: false,
    caisseContenu: [],
    uid: "",
    user: [],
    plateform: [],
    actionArticle: [],
    dataDevis: [],
    tempCommande: [],
    crossOeTab: [],
    comptoirSearchTab: [],
    prixTab: [],
    stockTab: [],
    vehicule: [],
    tabRecherchePS: [],
    vehiculeSet: false,
    vehiculeVin: false,
    vehiculeTD: false,
    vehiculeTDData: {},
    carcatToken: {
      id_token: "",
      access_token: "",
      refresh_token: "",
      expires_in: "",
      token_type: "",
    },
    carCatLink: "",
    carCatReference: "",
    // tabDsv: [],
    // tabArt: [],
    catalogueAGSelected: [],
    articleInfoData: {
      Ref_fournisseur: "",
      Code_marque: "",
    },
    selectionCarId: null,
    selectionCarData: {},
    tabClient: [],
    consigneData: [],
    consigneNonData: [],
    dsvCheck: false,
    plateformCheck: true,
    Ref_fournisseur_search: "",
    Ref_fournisseur_gen_search: "",
    genArt_search: "",
    clientChoosedTab: [],
    clientFocusDataDevis: "",
    refDataDevis: "",
    refClient: "",
    genericArticleSelected: [],
    familleCatalogueSelected: {},
    familleSelected: {},
    savedFamilleSelected: {},
    carIdSelected: 0,
    confGenArtSelected: 0,
    remiseTabAchat: [],
    idDetailPanierClient: "",
  },
  mutations: {
    DECONNECTER: (state) => (state.auth = false),
    CONNECTER: (state) => (state.auth = true),
    OUVRIR_CAISSE: (state) => (state.caisse = true),
    FERMER_CAISSE: (state) => (state.caisse = false),
    UPDATE_UID: (state, uid) => (state.uid = uid),
    SET_USER: (state, data) => {
      state.user = data;
      axios
        .get(
          `${process.env.VUE_APP_API}/plateform/getone/${state.user.plateform}`
        )
        .then((res) => {
          state.plateform = res.data[0];
        });
    },
    GET_PLATEFORM: (state) => {
      axios
        .get(
          `${process.env.VUE_APP_API}/plateform/getone/${state.user.plateform}`
        )
        .then((res) => {
          state.plateform = res.data[0];
        });
    },
    ADD_STOCKARTICLE: (state, data) => {
      axios.put(`${process.env.VUE_APP_API}/article/${data.id}`, {
        Stock: parseInt(data.Stock) + parseInt(data.inputstock),
      });
    },
    SUPP_STOCKARTICLE: (state, data) => {
      axios.put(`${process.env.VUE_APP_API}/article/${data.id}`, {
        Stock: parseInt(data.Stock) - parseInt(data.inputstock),
      });
    },
    SET_MVTSTOCK: (state, data) => {
      axios.post(`${process.env.VUE_APP_API}/mvStock`, {
        Date: data.Date,
        ArticleRef: data.Ref_fournisseur,
        ArticleEAN: data.Code_EAN,
        Depot: data.depot,
        User: state.user.username,
        Description: data.Description,
        OldStock: data.oldStock,
        NewStock: data.newStock,
      });
    },
    ADD_ACTIONARTICLE: (state, data) => {
      state.actionArticle.unshift(data);
    },
    SUPP_ACTIONARTICLE: (state, data) =>
      (state.actionArticle = state.actionArticle.filter(
        (item) => !data.includes(item._id)
      )),
    SUPP_ACTIONCONSIGNE: (state, data) =>
      (state.actionArticle = state.actionArticle.filter(
        (item) => !data.includes(item.Ref_fournisseur)
      )),
    ADD_DATADEVIS_MODIF: (state, data) => {
      state.dataDevis.push(data);
      state.remiseTabAchat.push(null);
    },
    ADD_DATADEVIS: (state, data) => {
      if (data.data2) {
        state.dataDevis.push(data.data);
        state.remiseTabAchat.push(data.data.remise);
      } else {
        state.dataDevis.unshift(data.data);
        state.remiseTabAchat.unshift(data.data.remise);
      }
      state.dataDevis[state.dataDevis.length - 1].remise = 0;
    },
    SUPP_DATADEVIS: (state, data) => (
      (state.dataDevis = state.dataDevis.filter(
        (item) => !data.id.includes(item._id)
      )),
      state.remiseTabAchat.splice(data.index, 1)
    ),
    CLEAR_DATADEVIS: (state) => (
      (state.dataDevis = []), (state.remiseTabAchat = [])
    ),
    SAVE_DATADEVIS: (state, data) => {
      state.refDataDevis = data.ref;
      state.refClient = data.refClient;
      state.clientFocusDataDevis = data.client;
    },
    UPDATE_REMISE_DATADEVIS: (state, data) => {
      state.dataDevis[data.indexArt].remise = data.remise;
      state.dataDevis[data.indexArt].prixfinal = data.prixfinal;
      state.dataDevis[data.indexArt].quantity = data.quantity;
      state.dataDevis[data.indexArt].prixtotal = data.prixtotal;
    },
    ADD_CAISSE: (state, data) => {
      state.caisseContenu.push(data);
    },
    SUPP_CAISSE: (state, data) =>
      (state.caisseContenu = state.caisseContenu.filter(
        (item) => !data.includes(item._id)
      )),
    CLEAR_CAISSE: (state) => {
      state.caisseContenu = [];
    },
    VEHICULE_TRUE: (state) => {
      state.vehiculeVin = true;
    },
    VEHICULE_FALSE: (state) => {
      state.vehiculeVin = false;
    },
    ADD_VEHICULE: (state, data) => {
      state.vehicule.push(data);
      state.vehiculeSet = true;
      state.vehiculeVin = true;
      state.vehiculeTD = false;
    },
    CLEAR_VEHICULE: (state) => {
      state.vehicule = [];
      state.vehiculeSet = false;
      state.vehiculeVin = false;
      state.vehiculeTD = false;
    },
    UPDATE_ACTIONARTICLE: (state, data) => (state.actionArticle = data),
    CLEAR_ACTIONARTICLE: (state) => (state.actionArticle = []),
    UPDATE_STOCK_ACTIONARTICLE: (state, data) => {
      state.actionArticle[data.indexArt].quantity = data.quantity;
      state.actionArticle[data.indexArt].prixtotal = data.prixtotal;
    },
    UPDATE_REMISE_ACTIONARTICLE: (state, data) => {
      state.actionArticle[data.indexArt].prixfinal = data.prixfinal;
      state.actionArticle[data.indexArt].prixtotal = data.prixtotal;
    },
    ADD_TEMP_COMMANDE: (state, data) => {
      state.tempCommande.push(data);
    },
    SEARCH_CROSS_OE: (state, data) => {
      state.prixTab = [];
      state.crossOeTab = [];
      state.tabRecherchePS = [];
      var crossOeTabTemp = [];
      state.generalLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getcross`, {
          Ref_fournisseur: data,
        })
        .then((res) => {
          // state.crossOeTab = res.data;
          crossOeTabTemp = res.data;
          // state.generalLoading = false;
        })
        .then(() => {
          crossOeTabTemp.forEach((item) => {
            // axios
            //   .post(`${process.env.VUE_APP_API}/article/getprix`, {
            //     Ref_fournisseur: item.articleNo,
            //   })
            //   .then((res) => {
            //     state.prixTab[index] = res.data;
            //   });
            state.tabRecherchePS.push({
              marque: item.brandName,
              ref_f: item.articleNo,
            });
          });
        })
        .then(() => {
          axios
            .post(`${process.env.VUE_APP_API}/articlecross/getps`, {
              Ref_fournisseur: state.tabRecherchePS,
            })
            .then((response) => {
              state.prixTab = response.data;
            })
            .then(() => {
              var tabPrixTemp = [];
              var crossTabTemp = [];
              crossOeTabTemp.forEach((el, index) => {
                if (
                  state.prixTab[index].stock != "ND" &&
                  state.prixTab[index].stock > 0
                ) {
                  crossTabTemp.push(el);
                  tabPrixTemp.push(state.prixTab[index]);
                }
              });
              crossOeTabTemp.forEach((el, index) => {
                if (
                  state.prixTab[index].prix != "ND" &&
                  (state.prixTab[index].stock < 1 ||
                    !state.prixTab[index].stock)
                ) {
                  crossTabTemp.push(el);
                  tabPrixTemp.push(state.prixTab[index]);
                }
              });
              state.crossOeTab = crossTabTemp;
              state.prixTab = tabPrixTemp;
              state.generalLoading = false;
            });
        });
    },
    SEARCH_CROSS: (state, data) => {
      state.prixTab = [];
      state.crossOeTab = [];
      var crossOeTabTemp = [];
      state.tabRecherchePS = [];
      state.generalLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getdirectcross`, {
          Ref_fournisseur: data,
        })
        .then((res) => {
          // state.crossOeTab = res.data;
          crossOeTabTemp = res.data;
          // state.generalLoading = false;
        })
        .then(() => {
          crossOeTabTemp.forEach((item) => {
            state.tabRecherchePS.push({
              marque: item.brandName,
              ref_f: item.articleNo,
            });
          });
        })
        .then(() => {
          axios
            .post(`${process.env.VUE_APP_API}/articlecross/getps`, {
              Ref_fournisseur: state.tabRecherchePS,
            })
            .then((response) => {
              state.prixTab = response.data;
            })
            .then(() => {
              var tabPrixTemp = [];
              var crossTabTemp = [];
              crossOeTabTemp.forEach((el, index) => {
                if (
                  state.prixTab[index].stock != "ND" &&
                  state.prixTab[index].stock > 0
                ) {
                  crossTabTemp.push(el);
                  tabPrixTemp.push(state.prixTab[index]);
                }
              });
              crossOeTabTemp.forEach((el, index) => {
                if (
                  state.prixTab[index].prix != "ND" &&
                  (state.prixTab[index].stock < 1 ||
                    !state.prixTab[index].stock)
                ) {
                  crossTabTemp.push(el);
                  tabPrixTemp.push(state.prixTab[index]);
                }
              });
              state.crossOeTab = crossTabTemp;
              state.prixTab = tabPrixTemp;
              state.generalLoading = false;
            });
        });
    },
    SEARCH_TRANSLATED_DIRECT: async (state, data) => {
      state.comptoirSearchTab = [];
      state.generalLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/gettranslateddirect`, {
          Ref_fournisseur: data,
        })
        .then((res) => {
          // if (res.data[0].article.length > 1) {
          //   axios
          //     .post(
          //       `${process.env.VUE_APP_API}/searchEngine/filterTabArticleByPriorityBrand`,
          //       {
          //         articleData: res.data[0].article,
          //         plateform: state.user.plateform,
          //       }
          //     )
          //     .then((response) => {
          //       res.data[0].article.forEach((elementA, indexA) => {
          //         if (elementA.Prefixe_tarif != response.data) {
          //           res.data[0].article.splice(indexA, 1);
          //         }
          //       });
          //       state.comptoirSearchTab = res.data;
          //       state.generalLoading = false;
          //     });
          // } else {
          state.comptoirSearchTab = res.data;
          state.generalLoading = false;
          // }
        });
    },
    SEARCH_TRANSLATED_DIRECT_ADD: async (state, data) => {
      // state.crossOeTab = [];
      // state.generalLoading = true;
      var dataTemp = data;
      var dataToADD = [];

      if (dataTemp.marqueName == "MEYLE") {
        const getRefCourte = await axios.post(
          `${process.env.VUE_APP_API}/catalogue/getRefCourte`,
          {
            Ref_fournisseur: dataTemp.artNum,
          }
        );
        dataTemp.artNum = getRefCourte.data[0].Ref_fournisseur;
      }

      var res = await axios.post(
        `${process.env.VUE_APP_API}/articlecross/gettranslateddirect`,
        {
          Ref_fournisseur: dataTemp,
        }
      );

      if (res.data[0].article) {
        if (data.Prefixe_tarif) {
          for (const [index, art] of res.data[0].article.entries()) {
            if (art.Prefixe_tarif == data.Prefixe_tarif) {
              dataToADD.push(art);
            }
          }
        } else {
          dataToADD = res.data[0].article;
        }
        if (dataToADD.length > 1) {
          res = await axios.post(
            `${process.env.VUE_APP_API}/searchEngine/filterTabArticleByPriority`,
            {
              articleData: dataToADD,
              plateform: state.user.plateform,
            }
          );
        }
        var existArticle = false;

        for (const [index, actArticle] of state.actionArticle.entries()) {
          if (
            actArticle.Ref_fournisseur == dataToADD[0].Ref_fournisseur &&
            actArticle.Prefixe_tarif == dataToADD[0].Prefixe_tarif
          ) {
            state.actionArticle[index].quantity =
              state.actionArticle[index].quantity + 1;
            existArticle = true;
          }
        }

        if (existArticle == false) {
          const checkPromo = await axios.post(
            `${process.env.VUE_APP_API}/promo/getone`,
            {
              promoinfo: {
                plateformID: state.user.plateform,
                Ref_fournisseur: dataToADD[0].Ref_fournisseur,
                Code_marque: dataToADD[0].Code_marque,
              },
            }
          );
          const foundFournisseur = await axios.post(
            `${process.env.VUE_APP_API}/fournisseur/getone`,
            {
              Prefixe_tarif: dataToADD[0].Prefixe_tarif,
              plateform: state.user.plateform,
              client: state.user.proprietaire,
            }
          );

          const getClient = await axios.post(
            `${process.env.VUE_APP_API}/client/getone/${state.user.proprietaire}`,
            {
              plateform: state.user.plateform,
            }
          );

          var groupClient = getClient.data[0].Group.Name;

          var dataTab = dataToADD[0];

          if (foundFournisseur.data.length > 0) {
            dataTab.PrixFournisseurType =
              foundFournisseur.data[0].typeCondition;
            dataTab.FournisseurSelected = foundFournisseur.data[0].CompanyName;

            if (foundFournisseur.data[0].typeCondition == "prixnet") {
              switch (groupClient) {
                case "T1":
                  dataTab.Prix_vente = dataToADD[0].Prix_euro;
                  dataTab.Prix_euro = dataToADD[0].PNT1;
                  break;
                case "T2":
                  dataTab.Prix_vente = dataToADD[0].Prix_euro;
                  dataTab.Prix_euro = dataToADD[0].PNT2;
                  break;
                case "T3":
                  dataTab.Prix_vente = dataToADD[0].Prix_euro;
                  dataTab.Prix_euro = dataToADD[0].PNT3;
                  break;
                case "T4":
                  dataTab.Prix_vente = dataToADD[0].Prix_euro;
                  dataTab.Prix_euro = dataToADD[0].PNT4;
                  break;
                case "T5":
                  dataTab.Prix_vente = dataToADD[0].Prix_euro;
                  dataTab.Prix_euro = dataToADD[0].PNT4;
                  break;
                case "T6":
                  dataTab.Prix_vente = dataToADD[0].Prix_euro;
                  dataTab.Prix_euro = dataToADD[0].PNT5;
                  break;
                default:
                  dataTab.Prix_vente = dataToADD[0].Prix_euro;
                  break;
              }
            } else if (foundFournisseur.data[0].typeCondition == "coef2") {
              dataTab.Prix_euro = dataToADD[0].Prix_ppc;
            } else if (foundFournisseur.data[0].typeCondition == "remise") {
              const clientCondition = await axios.post(
                `${process.env.VUE_APP_API}/conditionFournisseur/getClientCondition`,
                {
                  plateform: state.user.plateform,
                  fournisseur: dataToADD[0].FournisseurSelected,
                  article: dataToADD[0],
                }
              );

              switch (groupClient) {
                case "T1":
                  dataTab.remise = clientCondition.data[0].T1;
                  break;
                case "T2":
                  dataTab.remise = clientCondition.data[0].T2;
                  break;
                case "T3":
                  dataTab.remise = clientCondition.data[0].T3;
                  break;
                case "T4":
                  dataTab.remise = clientCondition.data[0].P1;
                  break;
                case "T5":
                  dataTab.remise = clientCondition.data[0].P2;
                  break;
                case "T6":
                  dataTab.remise = clientCondition.data[0].P3;
                  break;
                default:
                  dataTab.remise = 0;
                  break;
              }
            }
          } else {
            dataTab.PrixFournisseurType = "None";
            dataTab.FournisseurSelected = "Aucun";
            dataTab.Prix_vente = dataToADD[0].Prix_euro;
            dataTab.PNT1 = dataToADD[0].Prix_euro;
            dataTab.PNT2 = dataToADD[0].Prix_euro;
            dataTab.PNT3 = dataToADD[0].Prix_euro;
            dataTab.PNT4 = dataToADD[0].Prix_euro;
            dataTab.PNT5 = dataToADD[0].Prix_euro;
            dataTab.PNT6 = dataToADD[0].Prix_euro;
            dataTab.PNF = dataToADD[0].Prix_euro;
            dataTab.remise = 0;
          }

          checkPromo.data.length > 0
            ? (dataTab.Promo_set = true)
            : (dataTab.Promo_set = false);
          if (data.quantityAdded) {
            dataTab.quantity = data.quantityAdded;
          } else {
            dataTab.quantity = 1;
          }

          dataTab.commentaire = "";
          dataTab.prixtotal = dataTab.Prix_euro;
          dataTab.prixfinal = dataTab.Prix_euro;

          if (res.data[0].stockData) {
            res.data[0].stockData.length > 0
              ? (dataTab.stockData = res.data[0].stockData[0].stock)
              : (dataTab.stockData = 0);
          } else {
            dataTab.stockData = 0;
          }

          state.actionArticle.unshift(dataTab);

          if (dataTab.Montant_consigne && dataTab.Montant_consigne > 0) {
            state.actionArticle.unshift({
              _id: dataTab._id,
              Ref_fournisseur: "C : " + dataTab.Ref_fournisseur,
              Prix_euro: dataTab.Montant_consigne,
              Prix_vente: dataTab.Montant_consigne,
              PrixFournisseurType: "prixnet",
              quantity: 1,
              remise: 0,
              prixtotal: dataTab.Prix_euro,
              prixfinal: dataTab.Prix_euro,
              Code_marque: dataTab.Code_marque,
              Description: dataTab.Description,
            });
          }
        }
      }
      state.generalLoading = false;
    },
    SEARCH_DIRECT: (state, data) => {
      state.prixTab = [];
      state.crossOeTab = [];
      state.tabRecherchePS = [];
      var crossOeTabTemp = [];
      state.generalLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getdirect`, {
          Ref_fournisseur: data,
        })
        .then((res) => {
          // state.crossOeTab = res.data;
          crossOeTabTemp = res.data;
          // state.generalLoading = false;
        })
        .then(() => {
          crossOeTabTemp.forEach((item) => {
            state.tabRecherchePS.push({
              marque: item.brandName,
              ref_f: item.articleNo,
              plateform: state.user.plateform,
            });
          });
        })
        .then(() => {
          axios
            .post(`${process.env.VUE_APP_API}/articlecross/getps`, {
              Ref_fournisseur: state.tabRecherchePS,
            })
            .then((response) => {
              state.prixTab = response.data;
            })
            .then(() => {
              var tabPrixTemp = [];
              var crossTabTemp = [];
              crossOeTabTemp.forEach((el, index) => {
                if (state.prixTab[index]) {
                  if (
                    state.prixTab[index].stock != "ND" &&
                    state.prixTab[index].stock > 0
                  ) {
                    crossTabTemp.push(el);
                    tabPrixTemp.push(state.prixTab[index]);
                  }
                }
              });
              crossOeTabTemp.forEach((el, index) => {
                if (state.prixTab[index]) {
                  if (
                    state.prixTab[index].prix != "ND" &&
                    (state.prixTab[index].stock < 1 ||
                      !state.prixTab[index].stock)
                  ) {
                    crossTabTemp.push(el);
                    tabPrixTemp.push(state.prixTab[index]);
                  }
                }
              });
              state.crossOeTab = crossTabTemp;
              state.prixTab = tabPrixTemp;
              state.generalLoading = false;
            });
        });
    },
    ADD_COMPTOIR_SEARCH_TAB: (state, data) => {
      if (state.comptoirSearchTab.length == 0) {
        state.comptoirSearchTab.push({ article: data, stockData: [] });
      } else {
        for (const element of state.comptoirSearchTab) {
          var tabAjout = [];
          tabAjout.push(data);
          for (const el of tabAjout[0]) {
            element.article.push(el);
          }
        }
      }
    },
    GET_PRIX: async (state, data) => {
      const getprixV = await axios.post(
        `${process.env.VUE_APP_API}/article/getprix`,
        {
          Ref_fournisseur: data,
        }
      );
      return getprixV.data;
    },
    SET_PRIX_QUANTITATIF: (state, data) => {
      state.actionArticle[data.index].Prix_de_depart =
        state.actionArticle[data.index].Prix_euro;
      state.actionArticle[data.index].Prix_euro = data.prix;
      state.actionArticle[data.index].quantity = data.quantite;
      state.actionArticle[data.index].quantity_remise = data.quantite;
      state.actionArticle[data.index].remise_de_depart = data.remise_de_depart;
      state.actionArticle[data.index].prix_quantitatif = true;
    },
    REMOVE_PRIX_QUANTITATIF: (state, data) => {
      state.actionArticle[data.index].Prix_euro =
        state.actionArticle[data.index].Prix_de_depart;
      state.actionArticle[data.index].remise =
        state.actionArticle[data.index].remise_de_depart;
      state.actionArticle[data.index].prix_quantitatif = false;
    },
    STORE_CARCAT_TOKEN: (state, data) => {
      // carcatToken: {
      //   id_token: "",
      //   access_token: "",
      //   refresh_token: "",
      //   expires_in: "",
      //   token_type: "",
      // },
      state.carcatToken.id_token = data.id_token;
      state.carcatToken.access_token = data.access_token;
      state.carcatToken.refresh_token = data.refresh_token;
      state.carcatToken.expires_in = data.expires_in;
      state.carcatToken.token_type = data.token_type;
    },
    STORE_REFRESH_CARCAT_TOKEN: (state, data) => {
      // carcatToken: {
      //   id_token: "",
      //   access_token: "",
      //   refresh_token: "",
      //   expires_in: "",
      //   token_type: "",
      // },
      state.carcatToken.id_token = data.id_token;
      state.carcatToken.access_token = data.access_token;
      state.carcatToken.expires_in = data.expires_in;
      state.carcatToken.token_type = data.token_type;
    },
    CLEAR_CARCAT_TOKEN: (state) => {
      // carcatToken: {
      //   id_token: "",
      //   access_token: "",
      //   refresh_token: "",
      //   expires_in: "",
      //   token_type: "",
      // },
      state.carcatToken.id_token = "";
      state.carcatToken.access_token = "";
      state.carcatToken.refresh_token = "";
      state.carcatToken.expires_in = "";
      state.carcatToken.token_type = "";
      state.carCatLink = "";
      state.carCatReference = "";
    },
    SET_CARCAT_LINKS: (state, data) => {
      state.carCatLink = data.link;
      state.carCatReference = data.ref;
    },
    ADD_PANIER_CARCAT: async (state, data) => {
      for (const elementVH of data.lines) {
        var response = await axios.post(
          `${process.env.VUE_APP_API}/article/getonecarcat`,
          {
            Ref_fournisseur: elementVH.LineCode,
          }
        );
        var existArticle = false;

        for (const [index, actArticle] of state.actionArticle.entries()) {
          if (
            actArticle.Ref_fournisseur == response.data[0].Ref_fournisseur &&
            actArticle.Prefixe_tarif == response.data[0].Prefixe_tarif
          ) {
            state.actionArticle[index].quantity =
              state.actionArticle[index].quantity + elementVH.Quantity;
            existArticle = true;
          }
        }
        if (existArticle == false) {
          var tabResult = [];
          let plateform_mere = state.user.plateform;
          if (state.plateform.plateform_mere) {
            plateform_mere = state.plateform.plateform_mere;
          }
          tabResult = await prepareArticleClient(
            { article: response.data[0], stockData: [] },
            plateform_mere,
            state.user.proprietaire,
            state.tabClient.Group.Name,
            elementVH.Quantity
          );
          state.actionArticle.unshift(tabResult);
        }
      }
      router.push("/commandesf");
    },
    // ADD_ARTICLE_DSV: (state, data) => {
    //   state.tabDsv.push(data);
    // },
    // ADD_ARTICLE_NON_DSV: (state, data) => {
    //   state.tabArt.push(data);
    // },
    // CLEAR_ARTICLE_DSV: (state) => (state.tabDsv = []),
    // CLEAR_ARTICLE_NON_DSV: (state) => (state.tabArt = []),
    // SUPP_ARTICLE_DSV: (state, data) =>
    //   (state.tabDsv = state.tabDsv.filter((item) => !data.includes(item._id))),
    // SUPP_ARTICLE_NON_DSV: (state, data) =>
    //   (state.tabArt = state.tabArt.filter((item) => !data.includes(item._id))),
    SET_CATALOGUE_AGSELECTED: (state, data) => {
      state.catalogueAGSelected = data;
    },
    SET_ARTICLE_INFO: (state, data) => {
      const article = data;
      if (article.Code_marque === "MEYLE") {
        const updatedArticle = {
          ...article,
          Ref_fournisseur: article.Ref_complementaire,
        };
        state.articleInfoData = updatedArticle;
      } else {
        state.articleInfoData = article;
      }
    },
    SET_SELECTION_CAR_ID: (state, data) => {
      state.selectionCarId = data.carId;
      state.vehiculeSet = false;
      state.vehiculeTD = true;
      state.vehiculeTDData = {
        marque: data.marque,
        modele: data.modele,
        version: data.version,
        date: data.date,
      };
    },
    SET_TD_CAR_ID: (state, data) => {
      state.selectionCarId = data.carId;
      state.vehiculeSet = true;
      state.vehiculeTD = false;
    },
    SET_SELECTION_CAR_DATA: (state, data) => {
      state.selectionCarData.manuName = data.manuName;
      state.selectionCarData.modelselected = data.modelselected;
    },
    GET_TAB_CLIENT: async (state) => {
      let plateform_mere = state.user.plateform;
      if (state.plateform.plateform_mere) {
        plateform_mere = state.plateform.plateform_mere;
      }
      await axios
        .post(
          `${process.env.VUE_APP_API}/client/getone/${state.user.proprietaire}`,
          {
            plateform: plateform_mere,
          }
        )
        .then((res) => {
          state.tabClient = res.data[0];
        });
    },
    // dataNonConsigne
    ADD_DATA_NON_CONSIGNE: (state, data) => {
      state.consigneNonData.unshift(data);
    },
    SUPP_DATA_NON_CONSIGNE: (state, data) => {
      state.consigneNonData = state.consigneNonData.filter(
        (item) => !data.includes(item._id)
      );
    },
    CLEAR_DATA_NON_CONSIGNE: (state) => {
      state.consigneNonData = [];
    },
    SET_DSV_CHECK: (state, data) => {
      state.dsvCheck = data;
    },
    SET_PLATEFORM_CHECK: (state, data) => {
      state.plateformCheck = data;
    },
    // dataConsigne
    ADD_DATA_CONSIGNE: (state, data) => {
      state.consigneData.unshift(data);
    },
    UPDATE_REMISE_DATA_CONSIGNE: (state, data) => {
      state.consigneData.forEach((element) => {
        if (
          element.Ref_fournisseur == data.Articles.Ref_fournisseur &&
          element.Prefixe_tarif == data.Articles.Prefixe_tarif
        ) {
          element.remise = data.remise;
          element.prixfinal = data.prixfinal;
          element.quantity = data.quantity;
          element.prixtotal = data.prixtotal;
        }
      });
    },
    UPDATE_QTY_DATA_CONSIGNE: (state, data) => {
      state.consigneData.forEach((element) => {
        if (
          element.Ref_fournisseur == data.Articles.Ref_fournisseur &&
          element.Prefixe_tarif == data.Articles.Prefixe_tarif
        ) {
          element.quantity = data.quantity;
          element.prixtotal = element.prixtotal * data.quantity;
          element.prixfinal = -element.prixfinal;
        }
      });
    },
    SUPP_DATA_CONSIGNE: (state, data) => {
      state.consigneData = state.consigneData.filter(
        (item) => !data.includes(item._id)
      );
    },
    CLEAR_DATA_CONSIGNE: (state) => {
      state.consigneData = [];
    },
    SEARCH_REF_FOURNISSEUR_DETAIL: (state, data) => {
      state.Ref_fournisseur_search = data.Ref_fournisseur;
      state.genArt_search = "";
      if (router.currentRoute.value.path == "/searchArticleDetail") {
        router.push("/searchArticleDetailBis");
      } else {
        router.push("/searchArticleDetail");
      }
    },
    SEARCH_REF_FOURNISSEUR: (state, data) => {
      state.Ref_fournisseur_search = data.Ref_fournisseur;
      state.genArt_search = "";
      if (router.currentRoute.value.path == "/searchArticleExact") {
        router.push("/searchArticleExactBis");
      } else {
        router.push("/searchArticleExact");
      }
    },
    SEARCH_REF_FOURNISSEUR_GEN: (state, data) => {
      state.Ref_fournisseur_gen_search = data.Ref_fournisseur;
      state.genArt_search = data.genArt;
      if (data.Prefixe_tarif == "Vhi") {
        router.push("/searchArticleVH");
      } else {
        router.push("/searchArticleNew");
      }
    },
    GET_CLIENT_CHOOSED_TAB: (state, data) => {
      state.clientChoosedTab = data;
    },
    SET_GENERICARTICLE_SELECTED: (state, data) => {
      state.genericArticleSelected = data;
    },
    SET_FAMILLE_CATALOGUE: async (state, data) => {
      state.familleCatalogueSelected = data;
    },
    SET_FAMILLE_SELECTED: async (state, data) => {
      state.savedFamilleSelected = state.familleSelected;
      state.familleSelected = data.famille;
      if (data.carId) {
        state.carIdSelected = data.carId;
      }
    },
    SET_CONF_GEN_ART_SELECTED: async (state, data) => {
      state.confGenArtSelected = data;
      router.push("/ConfigurationGenArtBrand");
    },
    ADD_REMISE_TAB_ACHAT: (state, data) => {
      state.remiseTabAchat[data.index] = data.remise;
      state.dataDevis[data.index].remise = 0;
    },
    UPDATE_REMISE_TAB_ACHAT: (state, data) => {
      state.remiseTabAchat[data.index] = data.tab;
    },
    SUPP_REMISE_TAB_ACHAT: (state, data) =>
      (state.remiseTabAchat = state.remiseTabAchat.filter(
        (item) => !data.includes(item._id)
      )),
    SET_DETAIL_PANIER_CLIENT: async (state, data) => {
      state.idDetailPanierClient = data;
    },
  },
  actions: {
    deconnecter: (store) => store.commit("DECONNECTER"),
    connecter: (store) => store.commit("CONNECTER"),
    ouvrirCaisse: (store) => store.commit("OUVRIR_CAISSE"),
    fermerCaisse: (store) => store.commit("FERMER_CAISSE"),
    updateUid: (store, uid) => store.commit("UPDATE_UID", uid),
    setUser: async (store, uid) => {
      const user = await axios.get(
        `${process.env.VUE_APP_API}/user/getone/${uid}`
      );
      console.log("user : ", user);

      if (user) {
        const userData = user.data;
        store.commit("SET_USER", userData);
      } else {
        this.$router.push("/login");
      }
    },
    getplateform: (store, data) => {
      store.commit("GET_PLATEFORM", data);
    },
    addStockArticle: (store, data) => {
      store.commit("ADD_STOCKARTICLE", data);
    },
    suppStockArticle: (store, data) => {
      store.commit("SUPP_STOCKARTICLE", data);
    },
    setMvtStock: (store, data) => {
      store.commit("SET_MVTSTOCK", data);
    },
    addactionArticle: (store, data) => {
      store.commit("ADD_ACTIONARTICLE", data);
    },
    suppactionArticle: (store, data) => {
      store.commit("SUPP_ACTIONARTICLE", data);
    },
    suppactionconsigne: (store, data) => {
      store.commit("SUPP_ACTIONCONSIGNE", data);
    },
    clearactionArticle: (store) => {
      store.commit("CLEAR_ACTIONARTICLE");
    },
    adddatadevismodif: (store, data) => {
      store.commit("ADD_DATADEVIS_MODIF", data);
    },
    adddatadevis: (store, data) => {
      store.commit("ADD_DATADEVIS", data);
    },
    suppdatadevis: (store, data) => {
      store.commit("SUPP_DATADEVIS", data);
    },
    cleardatadevis: (store) => {
      store.commit("CLEAR_DATADEVIS");
    },
    savedatadevis: (store, data) => {
      store.commit("SAVE_DATADEVIS", data);
    },
    updateremisedatadevis: (store, data) => {
      store.commit("UPDATE_REMISE_DATADEVIS", data);
    },
    addcaisse: (store, data) => {
      store.commit("ADD_CAISSE", data);
    },
    suppcaisse: (store, data) => {
      store.commit("SUPP_CAISSE", data);
    },
    clearcaisse: (store) => {
      store.commit("CLEAR_CAISSE");
    },
    vehiculetrue: (store) => {
      store.commit("VEHICULE_TRUE");
    },
    vehiculefalse: (store) => {
      store.commit("VEHICULE_FALSE");
    },
    addvehicule: (store, data) => {
      store.commit("ADD_VEHICULE", data);
    },
    clearvehicule: (store) => {
      store.commit("CLEAR_VEHICULE");
    },
    updateactionArticle: (store, data) => {
      store.commit("UPDATE_ACTIONARTICLE", data);
    },
    updatestockactionArticle: (store, data) => {
      store.commit("UPDATE_STOCK_ACTIONARTICLE", data);
    },
    updateremiseactionArticle: (store, data) => {
      store.commit("UPDATE_REMISE_ACTIONARTICLE", data);
    },
    addtempCommande: (store, data) => {
      store.commit("ADD_TEMP_COMMANDE", data);
    },
    searchCrossOe: (store, data) => {
      store.commit("SEARCH_CROSS_OE", data);
    },
    searchCross: (store, data) => {
      store.commit("SEARCH_CROSS", data);
    },
    searchDirect: (store, data) => {
      store.commit("SEARCH_DIRECT", data);
    },
    addcomptoirsearchtab: (store, data) => {
      store.commit("ADD_COMPTOIR_SEARCH_TAB", data);
    },
    getPrix: (store, data) => {
      store.commit("GET_PRIX", data);
    },
    searchTranslatedDirect: (store, data) => {
      store.commit("SEARCH_TRANSLATED_DIRECT", data);
    },
    searchTranslatedDirectAdd: (store, data) => {
      store.commit("SEARCH_TRANSLATED_DIRECT_ADD", data);
    },
    setprixquantitatif: (store, data) => {
      store.commit("SET_PRIX_QUANTITATIF", data);
    },
    removeprixquantitatif: (store, data) => {
      store.commit("REMOVE_PRIX_QUANTITATIF", data);
    },
    storecarcattoken: (store, data) => {
      store.commit("STORE_CARCAT_TOKEN", data);
    },
    storerefreshcarcattoken: (store, data) => {
      store.commit("STORE_REFRESH_CARCAT_TOKEN", data);
    },
    clearcarcattoken: (store, data) => {
      store.commit("CLEAR_CARCAT_TOKEN", data);
    },
    addpaniercarcat: (store, data) => {
      store.commit("ADD_PANIER_CARCAT", data);
    },
    setcarcatlinks: (store, data) => {
      store.commit("SET_CARCAT_LINKS", data);
    },
    // addarticledsv: (store, data) => {
    //   store.commit("ADD_ARTICLE_DSV", data);
    // },
    // addarticlenondsv: (store, data) => {
    //   store.commit("ADD_ARTICLE_NON_DSV", data);
    // },
    // cleararticledsv: (store, data) => {
    //   store.commit("CLEAR_ARTICLE_DSV", data);
    // },
    // cleararticlenondsv: (store, data) => {
    //   store.commit("CLEAR_ARTICLE_NON_DSV", data);
    // },
    // supparticledsv: (store, data) => {
    //   store.commit("SUPP_ARTICLE_DSV", data);
    // },
    // supparticlenondsv: (store, data) => {
    //   store.commit("SUPP_ARTICLE_NON_DSV", data);
    // },
    setcatalogueagselected: (store, data) => {
      store.commit("SET_CATALOGUE_AGSELECTED", data);
    },
    setArticleInfo: (store, data) => {
      store.commit("SET_ARTICLE_INFO", data);
    },
    setSelectionCarId: (store, data) => {
      store.commit("SET_SELECTION_CAR_ID", data);
    },
    setTdCarId: (store, data) => {
      store.commit("SET_TD_CAR_ID", data);
    },
    setSelectionCarData: (store, data) => {
      store.commit("SET_SELECTION_CAR_DATA", data);
    },
    getTabClient: (store, data) => {
      store.commit("GET_TAB_CLIENT", data);
    },
    adddatanonconsigne: (store, data) => {
      store.commit("ADD_DATA_NON_CONSIGNE", data);
    },
    suppdatanonconsigne: (store, data) => {
      store.commit("SUPP_DATA_NON_CONSIGNE", data);
    },
    cleardatanonconsigne: (store, data) => {
      store.commit("CLEAR_DATA_NON_CONSIGNE", data);
    },
    searchRefFournisseurDetail: (store, data) => {
      store.commit("SEARCH_REF_FOURNISSEUR_DETAIL", data);
    },
    setdsvcheck: (store, data) => {
      store.commit("SET_DSV_CHECK", data);
    },
    setplateformcheck: (store, data) => {
      store.commit("SET_PLATEFORM_CHECK", data);
    },
    adddataconsigne: (store, data) => {
      store.commit("ADD_DATA_CONSIGNE", data);
    },
    updateremisedataconsigne: (store, data) => {
      store.commit("UPDATE_REMISE_DATA_CONSIGNE", data);
    },
    updateqtydataconsigne: (store, data) => {
      store.commit("UPDATE_QTY_DATA_CONSIGNE", data);
    },
    suppdataconsigne: (store, data) => {
      store.commit("SUPP_DATA_CONSIGNE", data);
    },
    cleardataconsigne: (store, data) => {
      store.commit("CLEAR_DATA_CONSIGNE", data);
    },
    searchRefFournisseur: (store, data) => {
      store.commit("SEARCH_REF_FOURNISSEUR", data);
    },
    searchRefFournisseurGen: (store, data) => {
      store.commit("SEARCH_REF_FOURNISSEUR_GEN", data);
    },
    getclientchoosedtab: (store, data) => {
      store.commit("GET_CLIENT_CHOOSED_TAB", data);
    },
    setgenericarticleselected: (store, data) => {
      store.commit("SET_GENERICARTICLE_SELECTED", data);
    },
    setfamillecatalogue: async (store, data) => {
      store.commit("SET_FAMILLE_CATALOGUE", data);
    },
    setfamilleselected: async (store, data) => {
      store.commit("SET_FAMILLE_SELECTED", data);
    },
    setConfGenArtSelected: async (store, data) => {
      store.commit("SET_CONF_GEN_ART_SELECTED", data);
    },
    addremisetabachat: async (store, data) => {
      store.commit("ADD_REMISE_TAB_ACHAT", data);
    },
    suppremisetabachat: async (store, data) => {
      store.commit("SUPP_REMISE_TAB_ACHAT", data);
    },
    setDetailPanierClient: async (store, data) => {
      store.commit("SET_DETAIL_PANIER_CLIENT", data);
    },
  },
  getters: {
    auth: (state) => state.auth,
    generalloading: (state) => state.generalLoading,
    caisse: (state) => state.caisse,
    caisseContenu: (state) => state.caisseContenu,
    uid: (state) => state.uid,
    user: (state) => state.user,
    plateform: (state) => state.plateform,
    actionArticle: (state) => state.actionArticle,
    dataDevis: (state) => state.dataDevis,
    tempCommande: (state) => state.tempCommande,
    vehicule: (state) => state.vehicule,
    vehiculeSet: (state) => state.vehiculeSet,
    vehiculeVin: (state) => state.vehiculeVin,
    vehiculeTD: (state) => state.vehiculeTD,
    vehiculeTDData: (state) => state.vehiculeTDData,
    crossoetab: (state) => state.crossOeTab,
    prixtab: (state) => state.prixTab,
    stocktab: (state) => state.stockTab,
    comptoirsearchtab: (state) => state.comptoirSearchTab,
    tabRecherchePS: (state) => state.tabRecherchePS,
    carcatToken: (state) => state.carcatToken,
    carCatLink: (state) => state.carCatLink,
    carCatReference: (state) => state.carCatReference,
    // tabDsv: (state) => state.tabDsv,
    // tabArt: (state) => state.tabArt,
    catalogueAGSelected: (state) => state.catalogueAGSelected,
    articleInfoData: (state) => state.articleInfoData,
    selectionCarId: (state) => state.selectionCarId,
    selectionCarData: (state) => state.selectionCarData,
    tabClient: (state) => state.tabClient,
    consigneData: (state) => state.consigneData,
    consigneNonData: (state) => state.consigneNonData,
    dsvCheck: (state) => state.dsvCheck,
    plateformCheck: (state) => state.plateformCheck,
    Ref_fournisseur_search: (state) => state.Ref_fournisseur_search,
    Ref_fournisseur_gen_search: (state) => state.Ref_fournisseur_gen_search,
    genArt_search: (state) => state.genArt_search,
    clientChoosedTab: (state) => state.clientChoosedTab,
    refDataDevis: (state) => state.refDataDevis,
    clientFocusDataDevis: (state) => state.clientFocusDataDevis,
    refClient: (state) => state.refClient,
    genericArticleSelected: (state) => state.genericArticleSelected,
    familleCatalogueSelected: (state) => state.familleCatalogueSelected,
    familleSelected: (state) => state.familleSelected,
    savedFamilleSelected: (state) => state.savedFamilleSelected,
    carIdSelected: (state) => state.carIdSelected,
    confGenArtSelected: (state) => state.confGenArtSelected,
    remiseTabAchat: (state) => state.remiseTabAchat,
    idDetailPanierClient: (state) => state.idDetailPanierClient,
  },
  modules: {},
  plugins: [createPersistedState()],
});
