<template>
  <div>Franco au mois</div>
  <div>
    <label for="month">Mois :</label>
    <select v-model="selectedMonth" id="month">
      <!-- Options pour les mois, vous pouvez les générer dynamiquement si nécessaire -->
      <option value="1">Janvier</option>
      <option value="2">Février</option>
      <!-- ... Ajoutez d'autres options pour les mois ... -->
    </select>

    <label for="year">Année :</label>
    <input v-model="selectedYear" type="number" id="year" />

    <button @click="getPanierTransport">Obtenir les données mensuelles</button>

    <!-- Afficher les résultats -->
    <div v-if="panierTransport">
      <p>Résultat :</p>
      <ul>
        <li v-for="item in panierTransport.result" :key="item._id">
          {{ item.date }} - Valeur : {{ item.valeur }}
        </li>
      </ul>
      <p>Total valeur : {{ panierTransport.totalValeur }}</p>
    </div>
  </div>
</template>

<script>
import { getByClientMonthly } from "@/hooks/transport/panierTransport.ts";
import { mapGetters } from "vuex";

export default {
  name: "MyStatistique",
  data() {
    return {
      selectedMonth: new Date().getMonth() + 1, // Mois actuel par défaut
      selectedYear: new Date().getFullYear(), // Année actuelle par défaut
      panierTransport: null,
      totalValeur: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getPanierTransport() {
      this.panierTransport = await getByClientMonthly(
        this.user.plateform,
        this.user.proprietaire,
        this.selectedMonth,
        this.selectedYear
      );
    },
  },
};
</script>
