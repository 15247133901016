<template>
  <div>
    <div
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 gap-3"
      v-if="plateform.typeCat == 'personnalise' && catalogueConfiguration[0]"
    >
      <div
        v-for="(ag, index) in catalogueConfiguration[0].configuration"
        :key="index"
      >
        <div
          class="p-2 rounded shadow bg-white w-80 h-fit ml-4 mt-4"
          v-if="ag.actif"
        >
          <div class="flex flex-col">
            <div class="flex flex-row items-center">
              <div class="w-4/5">
                <span
                  class="text-xl text-gray-600 hover:text-blue-600 hover:font-semibold cursor-pointer"
                  v-if="ag.named"
                  @click="setPersoChildCatalogue(index, ag.assemblyGroupNodeId)"
                  >{{ ag.newName }}</span
                >
                <span
                  v-else
                  class="text-xl text-gray-600 hover:text-blue-600 hover:font-semibold cursor-pointer"
                  @click="setPersoChildCatalogue(index, ag.assemblyGroupNodeId)"
                  >{{ ag.assemblyGroupName }}</span
                >
              </div>
              <span class="w-1/5">
                <!-- {{ ag.assemblyGroupNodeId }} -->
                <img
                  class="h-12 w-17 cursor-pointer"
                  :src="
                    require('../../assets/Icons_TecDoc/' +
                      ag.assemblyGroupNodeId +
                      '.png')
                  "
                  @click="
                    showArticles(
                      ag,
                      null,
                      'parent',
                      index,
                      ag.assemblyGroupNodeId
                    )
                  "
                />
              </span>
            </div>

            <div
              class="flex flex-row items-center"
              v-show="!listChildState[index]"
            >
              <span
                class="material-icons-outlined cursor-pointer"
                style="color: orange"
                @click="setPersoChildCatalogue(index, ag.assemblyGroupNodeId)"
                >keyboard_double_arrow_down</span
              >
            </div>

            <div
              class="flex flex-row"
              v-show="listChildState[index]"
              v-for="(child, indexC) in ag.child"
              :key="indexC"
            >
              <span
                class="flex flex-row items-center hover:text-blue-400 hover:font-semibold"
                v-if="child.actif"
              >
                <span class="material-icons-round"> arrow_right </span>
                <div>
                  <span
                    v-if="child.named"
                    class="text-xs cursor-pointer"
                    @click="
                      showArticles(
                        ag,
                        indexC,
                        'child',
                        null,
                        child.assemblyGroupNodeId
                      )
                    "
                    >{{ child.newName }}</span
                  >
                  <span
                    v-else
                    class="text-xs cursor-pointer"
                    @click="
                      showArticles(
                        ag,
                        indexC,
                        'child',
                        null,
                        child.assemblyGroupNodeId
                      )
                    "
                    >{{ child.assemblyGroupName }}</span
                  >
                </div>
              </span>
            </div>
            <div
              class="flex flex-row items-center"
              v-show="listChildState[index]"
            >
              <span
                class="text-sm text-blue-400 hover:text-blue-600 cursor-pointer"
                @click="listChildState[index] = !listChildState[index]"
                >Voir moins</span
              >
              <span
                class="material-icons-outlined cursor-pointer"
                style="color: orange"
                @click="listChildState[index] = !listChildState[index]"
              >
                keyboard_double_arrow_up
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 flex justify-center">
      <img src="../../assets/tecdoc_logo.jpg" class="" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      catalogueConfiguration: [],
      carID: 12345,
      carData: [],
      listChildState: [],
      agLinkedTab: [],
    };
  },
  computed: {
    ...mapGetters([
      "vehicule",
      "vehiculeSet",
      "vehiculeVin",
      "user",
      "plateform",
      "selectionCarId",
    ]),
  },
  methods: {
    ...mapActions(["setcatalogueagselected"]),
    getCarID() {
      if (this.selectionCarId != null && !this.vehiculeSet) {
        this.carID = this.selectionCarId;
        this.getCatalogueConfiguration();
      } else {
        if (this.vehiculeSet) {
          if (
            this.vehicule[0].data.AWN_k_type ||
            this.vehicule[0].data.AWN_type_mine
          ) {
            axios
              .post(`${process.env.VUE_APP_API}/catalogue/getCarIdByKType`, {
                ktype: this.vehicule[0].data.AWN_k_type,
                typeMine: this.vehicule[0].data.AWN_type_mine,
              })
              .then((response) => {
                this.carData = response.data.data.array[0];
                if (
                  this.vehicule[0].data.AWN_k_type.length > 0 &&
                  this.vehicule[0].data.AWN_k_type != "Not requested"
                ) {
                  this.carID = this.vehicule[0].data.AWN_k_type;
                } else {
                  this.carID = response.data.data.array[0].carId;
                }
                this.getCatalogueConfiguration();
              });
          }
        } else {
          this.$router.push("/tecdocsearch");
        }
      }
    },
    getCatalogueConfiguration() {
      if (this.plateform.typeCat == "personnalise") {
        axios
          .post(
            `${process.env.VUE_APP_API}/configurationCatalogue/getconfiguredcatalogue`,
            {
              plateform: this.user.plateform,
              TDCat: this.carID,
            }
          )
          .then((res) => {
            this.catalogueConfiguration = res.data;
            this.catalogueConfiguration[0].configuration.forEach(() => {
              this.listChildState.push(false);
            });
          });
        axios
          .post(`${process.env.VUE_APP_API}/catalogue/getAllAGChildLinked`, {
            infos: {
              car: this.carID,
            },
          })
          .then((res) => {
            this.agLinkedTab = res.data;
          });
      } else {
        this.$router.push("/configurationcatalogue");
      }
    },
    async setPersoChildCatalogue(indexAG, parentID) {
      if (!this.listChildState[indexAG]) {
        var childFound = [];
        for (const element of this.catalogueConfiguration[0].configuration[
          indexAG
        ].child) {
          for (const ag of this.agLinkedTab) {
            if (element.assemblyGroupNodeId === ag.assemblyGroupNodeId) {
              childFound.push(element);
            }
          }
        }
        this.catalogueConfiguration[0].configuration[indexAG].child =
          childFound;
      }
      this.listChildState[indexAG] = !this.listChildState[indexAG];
    },
    async showArticles(
      assemblyConfiguration,
      indexChild,
      type,
      indexAG,
      selectedAGID
    ) {
      if (type == "parent") {
        this.setPersoChildCatalogue(indexAG);
      }
      await this.setcatalogueagselected({
        assemblyGroupConfiguration: assemblyConfiguration,
        indexChild: indexChild,
        type: type,
        ID: selectedAGID,
        carID: this.carID,
      });
      this.$router.push("/catalogueTecDoc");
    },
  },
  mounted() {
    // location.reload();
    // this.$router.go();

    this.getCarID();
  },
};
</script>
<style></style>
