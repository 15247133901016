<template>
  <!-- Modal Detail Commande -->
  <div
    v-if="infoInCommande"
    :class="`modal ${
      !infoInCommande && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="infoInCommande = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 70rem"
      class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif du bon de commande</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="infoInCommande = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-1/3 ml-2">
            Référence client:
            {{ detailCommande.Note }}
          </div>

          <div class="flex flex-col my-auto items-center ml-auto mx-10">
            <span
              class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
              style="color: blue"
              @click="showCommande(detailCommande)"
            >
              download
            </span>
            <div>BC n° {{ detailCommande.Numero }}</div>
          </div>
        </div>

        <table v-if="detailCommande.groupedTab" class="min-w-full mt-4">
          <div class="overflow-y-auto max-h-[25rem]">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
              </tr>
            </thead>

            <tbody
              v-if="detailCommande.groupedTab.length > 0"
              class="bg-white w-fit"
            >
              <div
                v-for="(cmd, ind) in detailPdf"
                :key="ind"
                class="tableHEADTR"
              >
                <br />
                <div class="bg-gray-100">BC n°{{ cmd.Numero }}</div>

                <tr
                  v-for="(r, index) in detailCommande.groupedTab[ind].pdfData"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ r.ref }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-500">
                      {{ r.description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ r.quantite }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      parseFloat(
                        detailCommande.groupedTab[ind].Articles[index].Prix_euro
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailCommande.groupedTab[ind].Articles[index]
                          .PrixFournisseurType != 'prixnet'
                      "
                    >
                      {{ r.remise }} %
                    </div>
                    <div v-else>prix net</div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailCommande.groupedTab[ind].Articles[index]
                          .PrixFournisseurType != 'prixnet'
                      "
                    >
                      {{
                        parseFloat(
                          detailCommande.groupedTab[ind].Articles[index]
                            .Prix_euro -
                            detailCommande.groupedTab[ind].Articles[index]
                              .Prix_euro *
                              (r.remise / 100)
                        ).toFixed(2)
                      }}
                      €
                    </div>

                    <div v-else>
                      {{
                        parseFloat(
                          detailCommande.groupedTab[ind].Articles[index]
                            .Prix_vente
                        ).toFixed(2)
                      }}
                      €
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(r.total).toFixed(2) }}€
                  </td>
                </tr>
              </div>
            </tbody>
            <!-- 2 eme  -->
            <tbody
              v-else-if="detailCommande.groupedTab.length == 0"
              class="bg-white w-fit"
            >
              <tr
                v-for="(r, index) in detailPdf"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row items-center">
                    <span class="ml-4 text-sm leading-5 text-gray-900">
                      {{ r.ref }}
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="text-sm leading-5 text-gray-500">
                    {{ r.description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-sm leading-5">
                    {{ r.quantite }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{
                    parseFloat(
                      detailCommande.Articles[index].Prix_euro
                    ).toFixed(2)
                  }}
                  €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div
                    v-if="
                      detailCommande.Articles[index].PrixFournisseurType !=
                      'prixnet'
                    "
                  >
                    {{ r.remise }} %
                  </div>
                  <div v-else>prix net</div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div
                    v-if="
                      detailCommande.Articles[index].PrixFournisseurType !=
                      'prixnet'
                    "
                  >
                    {{
                      parseFloat(
                        detailCommande.Articles[index].Prix_euro -
                          detailCommande.Articles[index].Prix_euro *
                            (r.remise / 100)
                      ).toFixed(2)
                    }}
                    €
                  </div>

                  <div v-else>
                    {{
                      parseFloat(
                        detailCommande.Articles[index].Prix_vente
                      ).toFixed(2)
                    }}
                    €
                  </div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(r.total).toFixed(2) }}€
                </td>
              </tr>
            </tbody>
          </div>
          <thead class="tableHEADTR mt-12 border-t">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="text-sm leading-5 text-gray-900">
                  Total HT :
                  {{ parseFloat(detailCommande.TotalPrice).toFixed(2) }} €
                </div>
                <div
                  v-if="detailCommande.TotalPriceTTC"
                  class="text-sm leading-5 text-gray-500"
                >
                  Total TTC :
                  {{ parseFloat(detailCommande.TotalPriceTTC).toFixed(2) }} €
                </div>
                <div v-else class="text-sm leading-5 text-gray-500">
                  Total TTC :
                  {{
                    parseFloat(
                      detailCommande.TotalPrice +
                        detailCommande.TotalPrice * 0.2
                    ).toFixed(2)
                  }}
                  €
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <!-- Fin modal Detail Commande -->

  <searchInput
    placeholder="Rechercher dans bons de commande"
    @searchWord="search(1, 10, $event)"
    @catchFalse="searchCatchError = $event"
    :searchStarted="searchInProgress"
    :catchError="searchCatchError"
  />

  <div class="flex mt-4 space-x-4">
    <button
      v-if="archiveState == false"
      @click="getInfosCommande(1, 10, false)"
      class="bg-bluevk text-white p-2 rounded-md w-24 border border-bluevk"
    >
      En cours
    </button>
    <button
      v-else
      @click="getInfosCommande(1, 10, false)"
      class="bg-bluevk text-white p-2 rounded-md w-24 bg-opacity-50 border border-white hover:bg-opacity-80"
    >
      En cours
    </button>
    <button
      v-if="archiveState == true"
      @click="getInfosCommande(1, 10, true)"
      class="bg-bluevk text-white p-2 rounded-md w-24 border border-bluevk"
    >
      Autre
    </button>
    <button
      v-else
      @click="getInfosCommande(1, 10, true)"
      class="bg-bluevk text-white p-2 rounded-md w-24 bg-opacity-50 border border-white hover:bg-opacity-80"
    >
      Autre
    </button>
  </div>

  <table class="min-w-full">
    <thead>
      <tr>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Référence client</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Date</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Numéro</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Client</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Créé par</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          HT
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          TTC
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          PDF
        </th>
      </tr>
    </thead>

    <tbody class="bg-white">
      <tr
        v-for="(u, index) in infosCommande"
        :key="index"
        class="cursor-pointer hover:bg-gray-100"
      >
        <td
          class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
        >
          {{ u.Note }}
        </td>
        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div class="flex items-center">
            <div class="ml-4">
              <div class="text-sm font-medium leading-5 text-gray-900">
                {{ getFRDate(u.Date) }}
              </div>
            </div>
          </div>
        </td>

        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div class="text-sm leading-5 text-gray-900">
            {{ u.Numero }}
            <span
              @click="getDetailCommande(u)"
              title="Plus d'informations"
              class="material-icons-outlined text-base text-cyan-600"
            >
              info
            </span>
          </div>
        </td>

        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          {{ u.Client }}
        </td>

        <td
          class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
        >
          {{ u.Name }}
        </td>

        <td
          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
        >
          {{ parseFloat(u.TotalPrice).toFixed(2) }}
        </td>

        <td
          v-if="u.TotalPriceTTC"
          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
        >
          {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
        </td>
        <td
          v-else
          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
        >
          {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
          €
        </td>

        <td
          class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
        >
          <div class="flex space-x-4">
            <span
              class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
              @click="showCommande(u)"
            >
              download
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <pageNumber
    v-if="!searchActive"
    :pageStart="pageStart"
    :pageNext="pageNext"
    :tabList="infosCommandeLength"
    :pageTab="pageTab"
    :pageTotal="Math.ceil(infosCommandeLength / 10)"
    :clickFunction="(arg1, arg2) => getInfosCommande(arg1, arg2, archiveState)"
    :currentPage="currentPage"
    :typeList="'bons de commande'"
    @update:start="pageStart = $event"
    @update:tab="pageTab = $event"
    @update:next="pageNext = $event"
  />

  <pageNumber
    v-if="searchActive"
    :pageStart="pageStart"
    :pageNext="pageNext"
    :tabList="searchLength"
    :pageTab="pageTab"
    :pageTotal="Math.ceil(searchLength / 10)"
    :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
    :currentPage="currentPage"
    :typeList="'bons de commande'"
    @update:start="pageStart = $event"
    @update:tab="pageTab = $event"
    @update:next="pageNext = $event"
  />
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { createcommande } from "../../components/pdf/commande.js";
import { createGroupedDoc } from "../../components/pdf/groupedDoc.js";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyCommandes",
  data() {
    return {
      infosCommande: [],
      infosCommandeLength: 0,

      infoInCommande: false,
      detailCommande: [],
      detailPdf: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      archiveState: false,
    };
  },
  components: {
    pageNumber,
    searchInput,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosCommande(skipValue, limitValue, archive) {
      this.currentPage = skipValue;
      this.archiveState = archive;
      axios
        .post(`${process.env.VUE_APP_API}/commandes/getByClient`, {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
          archive: archive,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.infosCommande = response.data.tab;
          this.infosCommandeLength = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showCommande(objet) {
      if (objet.groupedTab && objet.groupedTab.length > 0) {
        this.openGroupedPDF(objet);
      } else {
        var today = new Date();
        var da = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        var dateedition = getFrDateSansH(objet.Date);
        var datepdf = da + "/" + mm + "/" + yyyy;
        if (dateedition == datepdf) {
          createcommande(objet, false);
        } else {
          createcommande(objet, true);
        }
      }
    },
    openGroupedPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createGroupedDoc(objet, "Bon de commande", "BC", false);
      } else {
        createGroupedDoc(objet, "Bon de commande", "BC", true);
      }
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosCommande(skipValue, limitValue, this.archiveState);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/commandes/searchInClient`, {
            plateform: this.user.plateform,
            client: this.user.proprietaire,
            archive: this.archiveState,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosCommande = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    getDetailCommande(data) {
      this.detailCommande = data;
      if (data.groupedTab && data.groupedTab.length > 0) {
        this.detailPdf = data.groupedTab;
      } else {
        this.detailPdf = data.pdfData;
      }
      this.infoInCommande = true;
    },
  },
  mounted() {
    this.getInfosCommande(1, 10, false);
    this.pageSwitch();
  },
};
</script>

<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
